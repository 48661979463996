import { gql } from '@apollo/client'

import { BUTTON_PREFERENCES_FRAGMENT } from './buttonPreferences'

export const PremiumThemeFragment = gql`
  fragment PremiumThemeFragment on PremiumTheme {
    dividerVariant
    buttonVariant
    primaryFont
    secondaryFont
    facebookHandle
    instagramHandle
    tiktokHandle
  }
`

export const UPDATE_STOREFRONT_APPEARANCE = gql`
  mutation SaveStorefrontAppearance($input: SaveStorefrontAppearanceInput!) {
    saveStorefrontAppearance(input: $input) {
      storefrontAppearance {
        image
      }
      errors {
        messages
        path
      }
    }
  }
`

export const GET_MY_THEME = gql`
  ${PremiumThemeFragment}
  ${BUTTON_PREFERENCES_FRAGMENT}

  query GetMyTheme {
    me {
      bakery {
        storefrontAppearance {
          image
          primaryColor
          background
        }
        premiumTheme {
          ...PremiumThemeFragment
        }
        buttonPreferences {
          ...ButtonPreferencesFragment
        }
      }
    }
  }
`

export const GET_THEME = gql`
  ${PremiumThemeFragment}
  ${BUTTON_PREFERENCES_FRAGMENT}

  query GetTheme($slug: String, $id: ID) {
    bakery(slug: $slug, id: $id) {
      storefrontAppearance {
        image
        primaryColor
        background
      }
      premiumTheme {
        ...PremiumThemeFragment
      }
      buttonPreferences {
        ...ButtonPreferencesFragment
      }
    }
  }
`

export const UPDATE_PREMIUM_THEME = gql`
  ${PremiumThemeFragment}
  mutation SavePremiumTheme($input: SavePremiumThemeInput!) {
    savePremiumTheme(input: $input) {
      premiumTheme {
        ...PremiumThemeFragment
      }
      errors {
        messages
        path
      }
    }
  }
`
