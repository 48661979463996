import * as Cross from 'shared/components/Cross'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Colors, stockTextColor } from 'shared/styles/Colors'
import { covertStockToDozens, parseNumberOrNull } from 'shared/utils/number'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { TextField } from 'web/components/form/TextField'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const Quantity = ({ order, setOrder }) => {
  const { bakery } = order

  const [orderItems, setOrderItems] = useState([])

  useEffect(() => {
    setOrderItems(
      order.orderItems.map((oi) => {
        const offering = {
          ...bakery.selectedBakedGoods.find((o) => o.id === oi.offeringId),
          ...bakery.selectedPresaleItems.find((o) => o.id === oi.offeringId),
          ...bakery.selectedMenuItems.find((o) => o.id === oi.offeringId),
        }

        return {
          offeringId: oi.offeringId,
          quantity: oi.quantity || offering.minQuantity,
          unit: oi.unit || offering.defaultUnit,
          spec: oi.spec,
        }
      }),
    )
  }, [order, bakery])

  const valid = orderItems.every((oi) => oi.quantity > 0)
  const { loading, updateOrder } = useUpdateOrder({ order, setOrder })

  const validateQuantity = (orderItem, offering) => {
    const { name, minQuantity, maxQuantity, dozenOnly } = offering

    if (offering.stock !== null && offering.stock !== undefined) {
      const stock = covertStockToDozens(offering.stock, dozenOnly)

      if (orderItem.quantity > stock) {
        alert(`${name}: Only ${stock}${dozenOnly ? ' dozen' : ''} left in stock`)
        return false
      }
    }

    const validateMinMax = () => {
      if (orderItem.quantity > maxQuantity || orderItem.quantity < minQuantity) {
        alert(`${name}: Please enter a quantity between ${minQuantity} and ${maxQuantity}`)
        return false
      }
      return true
    }

    const validateMin = () => {
      if (orderItem.quantity < minQuantity) {
        alert(`${name}: Please enter a quantity of at least ${minQuantity}`)
        return false
      }
      return true
    }

    switch (true) {
      case offering.allowMaxQuantity:
        return validateMinMax()

      default:
        return validateMin()
    }
  }

  const validateUnit = (orderItem, offering) => {
    switch (true) {
      case offering.dozenOnly && offering.allowHalfDozen:
        if (orderItem.quantity % 0.5 === 0) return true

        alert(`${offering.name}: Please enter a quantity in increments of 0.5 (1/2 dozen)`)
        return false

      default:
        if (orderItem.quantity % 1 === 0) return true

        alert(`${offering.name}: Please enter a quantity in increments of 1`)
    }
  }

  const onNext = ({ navigateToNextScreen }) => {
    const proceed = orderItems.map((orderItem) => {
      const offering = {
        ...bakery.selectedBakedGoods.find((o) => o.id === orderItem.offeringId),
        ...bakery.selectedPresaleItems.find((o) => o.id === orderItem.offeringId),
        ...bakery.selectedMenuItems.find((o) => o.id === orderItem.offeringId),
      }

      if (!validateQuantity(orderItem, offering)) return false
      if (!validateUnit(orderItem, offering)) return false
      return true
    })

    if (proceed.includes(false)) return

    updateOrder({
      afterUpdate: navigateToNextScreen,
      input: {
        orderItems: orderItems.map((orderItem) => ({
          ...orderItem,
          quantity: parseNumberOrNull(orderItem.quantity),
        })),
      },
    })
  }

  const handleChange = (offeringId) => (e) =>
    setOrderItems((_orderItems) =>
      _orderItems.map((orderItem) => {
        if (orderItem.offeringId !== offeringId) return orderItem
        return { ...orderItem, quantity: e.target.value }
      }),
    )

  const renderInfo = (offering) => {
    switch (true) {
      case offering.dozenOnly && offering.allowHalfDozen:
        return <Info>* increments of '0.5' (1/2 dozen)</Info>

      default:
        return null
    }
  }

  const renderQuantities = (offering) => {
    switch (true) {
      case offering.allowMaxQuantity && offering.minQuantity > 1:
        return (
          <Info $marginTop={2}>
            ({offering.minQuantity} min - {offering.maxQuantity} max)
          </Info>
        )

      case offering.allowMaxQuantity:
        return <Info $marginTop={2}>({offering.maxQuantity} max)</Info>

      case offering.minQuantity > 1:
        return <Info $marginTop={2}>({offering.minQuantity} min)</Info>

      default:
        return null
    }
  }

  return (
    <OrderRequestLayout nextDisabled={!valid || loading} onNext={onNext} order={order}>
      <Header>How many do you need?</Header>

      <Container>
        {orderItems.map((orderItem) => {
          const offering = {
            ...bakery.selectedBakedGoods.find((o) => o.id === orderItem.offeringId),
            ...bakery.selectedPresaleItems.find((o) => o.id === orderItem.offeringId),
            ...bakery.selectedMenuItems.find((o) => o.id === orderItem.offeringId),
          }

          const { dozenOnly, id, name } = offering
          const stock = covertStockToDozens(offering.stock, dozenOnly)

          return (
            <QuantityForm key={id}>
              <LabelContainer>
                <Label>{name}</Label>
              </LabelContainer>
              {renderInfo(offering)}

              {stock !== null && stock <= 10 && (
                <StockLabel stock={stock}>
                  ({`${stock} ${dozenOnly ? 'dozen' : ''} left`})
                </StockLabel>
              )}

              <InputContainer>
                <TextField
                  style={{ width: '30%' }}
                  type="number"
                  value={orderItem.quantity}
                  onChange={handleChange(id)}
                  min={0}
                />

                <Helper>
                  <Strong>{offering.dozenOnly ? 'Dozen' : 'Quantity'}</Strong>
                  {renderQuantities(offering)}
                </Helper>
              </InputContainer>
            </QuantityForm>
          )
        })}
      </Container>
    </OrderRequestLayout>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const QuantityForm = styled.div({
  flexDirection: 'column',
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  width: 280,
  marginBottom: 20,
})

const Label = styled.div({
  width: 'auto',
  maxWidth: '100%',
  fontWeight: 600,
})

const Info = styled.div(({ $marginTop = 0 }) => ({
  fontSize: 16,
  color: Colors.grey50,
  fontStyle: 'italic',
  marginTop: $marginTop,
  whiteSpace: 'nowrap',
}))

const InputContainer = styled.div({
  flexDirection: 'row',
  display: 'flex',
  marginTop: 10,
})

const Helper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 10,
  paddingTop: 10,
})

const Strong = styled(Cross.Strong)({
  marginRight: 10,
})

const StockLabel = styled(Label)(({ stock }) => ({
  marginTop: 10,
  fontSize: '1.5rem',
  fontStyle: 'italic',
  color: stockTextColor(stock),
}))

const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  width: '100%',
})
