import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { faCartShoppingFast } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors, stockTextColor } from 'shared/styles/Colors'
import { covertStockToDozens } from 'shared/utils/number'
import { DescriptionModal } from './DescriptionModal'
import { formatCents } from 'shared/utils/currency'
import { StyledIcon } from '.'

export const PresaleItemComponent = memo(
  ({ item, selected, background, onClick, currency, logo, marginTop, isInstantCheckout }) => {
    const [showModal, setShowModal] = useState(false)

    const imageUrl = item.image || logo
    const dozenOnly = item.dozenOnly
    const stock = covertStockToDozens(item.stock, dozenOnly)
    const disabled = stock !== null ? stock <= 0 : false

    const handleItemClick = () => {
      if (!disabled) onClick()
    }

    const handleOnDescClick = (e) => {
      if (item.description) {
        e.stopPropagation()
        setShowModal(true)
      }
    }

    return (
      <>
        <PresaleItem
          key={item.id}
          selected={selected}
          selectedColor={background}
          onClick={handleItemClick}
          marginTop={marginTop}
          disabled={disabled}
        >
          <StyledIcon icon={selected ? faCheck : faPlus} selected={selected} />
          {imageUrl && <PresaleItemImage src={imageUrl} alt={item.name} />}
          <PriceAndName>
            <Name>{item.name}</Name>
            {item.priceType !== 'noPrice' && (
              <Price>{`${item.priceType === 'variable' ? 'Starting at' : ''} ${formatCents(
                item.priceCents,
                currency,
              )}`}</Price>
            )}
          </PriceAndName>

          <InfoContainer onClick={handleOnDescClick}>
            {stock !== null && stock <= 10 && (
              <ItemStock stock={stock}>
                {stock > 0 ? `${stock} ${dozenOnly ? 'dozen' : ''} left` : 'Out of stock'}
              </ItemStock>
            )}
            {item.description && <InfoIcon icon={faInfoCircle} />}
            {isInstantCheckout && (
              <InstantCheckoutWrapper>
                <FontAwesomeIcon icon={faCartShoppingFast} />
                <InstanCheckoutText>Instant Checkout</InstanCheckoutText>
              </InstantCheckoutWrapper>
            )}
          </InfoContainer>
        </PresaleItem>

        {showModal && <DescriptionModal offering={item} onClose={() => setShowModal(false)} />}
      </>
    )
  },
)

const PresaleItem = styled.div(({ selected, selectedColor, disabled, marginTop = 0 }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  backgroundColor: disabled
    ? Colors.grey10
    : selected
    ? selectedColor && selectedColor !== Colors.defaultBackground
      ? selectedColor
      : Colors.offeringSelectedBgColor
    : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '10px 20px',
  marginBottom: 20,
  alignItems: 'center',
  cursor: 'pointer',
  height: 110,
  justifyContent: 'center',
  minWidth: '300px',
  width: '100%',
  marginTop,
}))

const PresaleItemImage = styled.img({
  width: 80,
  height: 80,
  marginRight: 10,
  marginTop: 10,
  marginBottom: 10,
})

const PriceAndName = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: 'calc(100% - 115px)',
})

const Name = styled.div({
  fontSize: 14,
  marginBottom: 10,
  fontWeight: 'bolder',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Price = styled.div({
  fontSize: 14,
  fontWeight: 'bold',
})

const InfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  padding: '10px 10px 5px',
  marginRight: -18,
  height: '100%',
})

const InfoIcon = styled(FontAwesomeIcon)({ fontSize: 24, lineHeight: 0 })

const InstantCheckoutWrapper = styled.div({
  position: 'absolute',
  display: 'flex',
  gap: 5,
  bottom: 10,
  right: 10,
})

const InstanCheckoutText = styled.span({
  fontSize: 14,
})

const ItemStock = styled.div(({ stock }) => ({
  fontWeight: 'bold',
  position: 'absolute',
  top: 5,
  right: 8,
  color: stockTextColor(stock),
  fontSize: 12,
}))
