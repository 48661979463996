import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'

import { Collapsible } from 'web/components/Collapsible'
import { Colors } from 'shared/styles/Colors'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { PresaleItemComponent } from './PresaleItem'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const BakedGoods = ({ order, setOrder, bakerySlug }) => {
  const { bakery } = order

  const { state } = useLocation()
  const { backgroundColor } = useThemeContext()

  const allItems = useMemo(
    () => [...bakery.selectedPresaleItems, ...bakery.selectedMenuItems],
    [bakery.selectedPresaleItems, bakery.selectedMenuItems],
  )

  const defaultOrderItems = useMemo(
    () =>
      order.orderItems.map((item) => ({
        offeringId: item.offeringId,
        quantity: item.quantity,
        unit: item.unit,
        spec: item.spec,
      })),
    [order],
  )

  const stateItems = useMemo(() => {
    const selectedItems = [
      ...bakery.selectedPresaleItems.filter((item) => item.id === state?.itemId),
      ...bakery.selectedMenuItems.filter((item) => item.id === state?.itemId),
    ]

    return selectedItems.map((item) => ({
      offeringId: item.id,
      quantity: 0,
    }))
  }, [state, bakery.selectedMenuItems, bakery.selectedPresaleItems])

  const bakeryLogo = useMemo(() => bakery.user.avatar?.largeUrl, [bakery.user])
  const [orderItems, setOrderItems] = useState([...defaultOrderItems, ...stateItems])

  const { loading, updateOrder } = useUpdateOrder({ order, setOrder })
  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({ afterUpdate: navigateToNextScreen, input: { orderItems } })
  }

  const toggleOrderItem = useCallback((offeringId) => {
    setOrderItems((prevOrderItems) => {
      const exists = prevOrderItems.some((oi) => oi.offeringId === offeringId)

      if (exists) {
        return prevOrderItems.filter((item) => item.offeringId !== offeringId)
      } else {
        return [...prevOrderItems, { offeringId, quantity: 0 }]
      }
    })
  }, [])

  const selectedItems = useMemo(
    () => allItems.filter((itm) => orderItems.some((oi) => oi.offeringId === itm.id)),
    [orderItems, allItems],
  )

  const itemsByCategories = useMemo(() => {
    const items = allItems.filter((itm) => !selectedItems.some((si) => si.id === itm.id))

    return items.reduce((acc, offering) => {
      const position = String(offering.category?.position || 9999)

      const key = 'category-' + position.padStart(3, '0')

      if (!acc[key]?.length) return { ...acc, [key]: [offering] }
      return { ...acc, [key]: [...acc[key], offering] }
    }, {})
  }, [selectedItems, allItems])

  const selectedBakedGoods = useMemo(
    () =>
      bakery.selectedBakedGoods.filter((itm) => orderItems.some((oi) => oi.offeringId === itm.id)),
    [orderItems, bakery.selectedBakedGoods],
  )

  const allBakedGoods = useMemo(
    () => bakery.selectedBakedGoods.filter((bg) => !selectedBakedGoods.includes(bg)),
    [selectedBakedGoods, bakery.selectedBakedGoods],
  )

  return (
    <OrderRequestLayout
      nextDisabled={!orderItems.length || loading}
      onNext={onNext}
      bakerySlug={bakerySlug}
      order={order}
    >
      <Header>What would you like to order?</Header>

      <OffersContainer>
        {selectedItems.map((offering) => (
          <PresaleItemComponent
            key={offering.id}
            item={offering}
            background={backgroundColor}
            onClick={() => toggleOrderItem(offering.id)}
            logo={bakeryLogo}
            currency={bakery.currency}
            isInstantCheckout={!!bakery.instantCheckoutMethod && offering.enableInstantCheckout}
            selected
          />
        ))}

        {selectedBakedGoods.map((offering) => (
          <Offering
            key={offering.id}
            selected
            selectedColor={backgroundColor}
            onClick={() => toggleOrderItem(offering.id)}
          >
            <StyledIcon icon={faCheck} selected />
            {offering.name}
          </Offering>
        ))}

        {Object.keys(itemsByCategories)
          .sort()
          .map((catKey, catIdx) => (
            <Collapsible
              key={catKey}
              expanded={catIdx === 0}
              Header={itemsByCategories[catKey][0].category?.name || 'Uncategorized'}
              headerStyle={CollapsibleHeaderStyle}
              containerStyle={{
                padding: '15px 0 0',
                marginBottom: catIdx + 1 === Object.keys(itemsByCategories).length ? 20 : 0,
              }}
            >
              {itemsByCategories[catKey].map((offering, offeringIdx) => (
                <PresaleItemComponent
                  key={offering.id}
                  item={offering}
                  background={backgroundColor}
                  onClick={() => toggleOrderItem(offering.id)}
                  logo={bakeryLogo}
                  currency={bakery.currency}
                  isInstantCheckout={
                    !!bakery.instantCheckoutMethod && offering.enableInstantCheckout
                  }
                  marginTop={offeringIdx === 0 ? 20 : undefined}
                />
              ))}
            </Collapsible>
          ))}

        {allBakedGoods.map((offering) => (
          <Offering
            key={offering.id}
            selectedColor={backgroundColor}
            onClick={() => toggleOrderItem(offering.id)}
          >
            <StyledIcon icon={faPlus} />
            {offering.name}
          </Offering>
        ))}
      </OffersContainer>
    </OrderRequestLayout>
  )
}

const OffersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'column',
})

const Offering = styled.div(({ selected, selectedColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  backgroundColor: selected
    ? selectedColor && selectedColor !== Colors.defaultBackground
      ? selectedColor
      : Colors.offeringSelectedBgColor
    : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '5px 20px',
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 20,
  cursor: 'pointer',
}))

export const StyledIcon = styled(FontAwesomeIcon)(({ selected }) => ({
  marginRight: 10,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
}))

const CollapsibleHeaderStyle = {
  marginBottom: 0,
  fontSize: 22,
  paddingBottom: 14,
  borderBottomWidth: 2,
  borderColor: Colors.grey25,
  borderBottomStyle: 'solid',
}
