import React from 'react'
import { GalleryList } from './GalleryList'

const buildListData = (images, { maxPlaceholders }) => {
  const data = [...images]

  while (data.length < maxPlaceholders) {
    const i = data.length
    data.push({ id: i })
  }

  return [{ id: 'add' }, ...data]
}

export const Gallery = React.memo(({ data, maxPlaceholders, ...props }) => {
  return <GalleryList data={buildListData(data, { maxPlaceholders })} {...props} />
})
