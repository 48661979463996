import React, { useMemo } from 'react'

import * as Cross from 'shared/components/Cross'
import { Configuration } from 'shared/services/Configuration'
import { Divider, Row, Strong } from './styles'
import { formatISODate } from 'shared/utils/dates'
import { OpenMapButton } from './'
import { removeUnderscores } from 'shared/utils/textTransforms'
import { StateLabel } from 'shared/components/StateLabel'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const StatusSection = ({ Container = React.Fragment }) => {
  const { order, isPendingConfirmation } = useInvoiceContext()
  const { sentAt, completionDate, pickupAddress } = order

  const state = useMemo(() => {
    const isImported = order.import && order.state === 'paid'

    if (isImported) return 'Imported'
    if (isPendingConfirmation) return 'pendingConfirmation'
    return order.state
  }, [order.import, order.state, isPendingConfirmation])

  return (
    <Container>
      <Row $marginBottom={5}>
        <Strong $marginRight={5}>Order ID:</Strong>
        <Cross.Text selectable={Configuration.isNative || undefined}>
          {order.orderNumber}
        </Cross.Text>
      </Row>

      {sentAt && (
        <Row $marginBottom={5}>
          <Strong $marginRight={5}>Created:</Strong>
          <Cross.Text>{formatISODate(sentAt)}</Cross.Text>
        </Row>
      )}

      {completionDate && (
        <Row $marginBottom={5}>
          <Strong $marginRight={5}>Order Due:</Strong>
          <Cross.Text>{formatISODate(completionDate)}</Cross.Text>
        </Row>
      )}

      <Row $marginBottom={5}>
        <Strong $marginRight={5}>Status:</Strong>
        <StateLabel state={state} />
      </Row>
      {pickupAddress && (
        <>
          <Row>
            <Strong>{removeUnderscores(order.addressType)}:</Strong>
          </Row>

          <OpenMapButton pickupAddress={pickupAddress} />
        </>
      )}

      <Divider />
    </Container>
  )
}
