import { useMutation } from '@apollo/client'

import { mapGraphqlErrors } from 'shared/utils/mapGraphqlErrors'
import { UPDATE_ORDER_REQUEST, UPDATE_ORDER_STATUS } from 'shared/queries/orders'
import { useLoading } from 'shared/hooks/useLoading'

export const useUpdateOrder = ({ order, setOrder }) => {
  const { loading, withLoading } = useLoading()
  const [updateOrderRequest] = useMutation(UPDATE_ORDER_REQUEST)
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS)

  const updateOrder = async ({ input, afterUpdate }) =>
    withLoading(async () => {
      try {
        const {
          data: {
            updateOrderDetails: { errors, order: updatedOrder },
          },
        } = await updateOrderRequest({
          variables: {
            input: {
              id: order.id,
              ...input,
            },
          },
        })
        if (!errors.length) {
          setOrder(updatedOrder)
          if (afterUpdate) afterUpdate()
          return { updatedOrder }
        } else {
          return mapGraphqlErrors(errors)
        }
      } catch (e) {
        console.error(e)
        alert('Error saving order details.')
      }
    })

  const saveOrder = async ({ input, afterUpdate }) =>
    withLoading(async () => {
      try {
        const {
          data: {
            updateOrderStatus: { errors, order: updatedOrder },
          },
        } = await updateOrderStatus({
          variables: {
            input: {
              id: order.id,
              ...input,
            },
          },
        })
        if (!errors.length) {
          setOrder(updatedOrder)
          if (afterUpdate) afterUpdate()
        } else {
          return mapGraphqlErrors(errors)
        }
      } catch (e) {
        console.error(e)
        alert('Error saving order details.')
      }
    })

  return { loading, updateOrder, saveOrder }
}
