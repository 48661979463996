import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import React, { useState } from 'react'
import styled from 'styled-components'

import { CalendarComponent } from 'web/components/Calendar'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { StyledTextArea } from 'web/components/form/TextAreaField'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const CompletionDate = ({ order, setOrder }) => {
  const { bakery } = order

  const [completionDate, setCompletionDate] = useState(
    order.completionDate ? parseISO(order.completionDate) : null,
  )

  const { loading, updateOrder } = useUpdateOrder({ order, setOrder })
  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({
      afterUpdate: navigateToNextScreen,
      input: { completionDate: formatISO(completionDate, { representation: 'date' }) },
    })
  }

  return (
    <OrderRequestLayout nextDisabled={!completionDate || loading} onNext={onNext} order={order}>
      <StyledHeader>When do you need this order?</StyledHeader>
      <CalendarComponent value={completionDate} onChange={setCompletionDate} bakery={bakery} />
    </OrderRequestLayout>
  )
}

export const StyledInput = styled(StyledTextArea)({
  flex: 1,
})

export const StyledHeader = styled(Header)({})
