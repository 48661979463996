import React from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { FetchError } from 'shared/components/FetchError'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { MainSubtitle } from './Details'
import { setLightboxImage } from 'shared/reducers/ui'
import { Spinner } from 'shared/components/Spinner'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const ImageWidthMap = {
  md: '33.33%',
  lg: '25%',
}

const BAKERY = gql`
  query getBakery($slug: String) {
    bakery(slug: $slug) {
      galleryImages {
        id
        thumbnailUrl
        fullUrl
        position
      }
    }
  }
`

export const Gallery = () => {
  const { isMdUp } = useResponsiveLayout()
  const { currentSize } = useResponsiveLayout()
  const dispatch = useDispatch()
  const { layoutHeight } = useWindowDimensions()
  const { primaryFont } = useThemeContext()

  const { slug } = useParams()
  const { data, loading, error, refetch } = useQuery(BAKERY, { variables: { slug } })

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  const {
    bakery: { galleryImages },
  } = data || {}

  return (
    <Container minHeight={layoutHeight}>
      <MainColumn isMdUp={isMdUp}>
        <MainSubtitle font={primaryFont}>Gallery</MainSubtitle>
        <Images>
          {galleryImages.map((image) => (
            <GalleryImageWrapper key={image.id} width={ImageWidthMap[currentSize] || '50%'}>
              <GalleryImage
                src={image.thumbnailUrl}
                onClick={() => dispatch(setLightboxImage(image.fullUrl))}
              />
            </GalleryImageWrapper>
          ))}
        </Images>
      </MainColumn>
    </Container>
  )
}
const Container = styled(FixedWidthContainer)(
  ({ minHeight }) => `
margin-bottom: 40px;
padding-top: 5px;
border-radius: 4px;
background-color: ${Colors.white};
min-height: ${minHeight - 34}px;

@media ${MediaQueries.mdUp} {
  margin-top: 30px;
  min-height: ${minHeight - 64}px;
}
`,
)

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`

const GalleryImageWrapper = styled.div`
  padding: 5px;
  width: ${(props) => props.width};
`

const GalleryImage = styled.img`
  width: 100%;
  border-radius: 4px;
  background-color: ${Colors.gre25};
  cursor: pointer;
`

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`
