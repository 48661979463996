import React from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

import { Colors } from 'shared/styles/Colors'
import { fontPositionAdjustments } from 'shared/styles/PremiumThemes'
import { Icon } from 'shared/components/Icon/Icon'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const BooleanInput = React.memo(
  ({ label, containerStyle = {}, labelStyle = {}, name, ...rest }) => {
    const { secondaryFontRaw } = useThemeContext()

    return (
      <Label htmlFor={name} style={containerStyle}>
        <StyledInput type="checkbox" name={name} id={name} {...rest} />
        <Checkbox className="check">
          <Icon icon={faCheck} size={20} />
        </Checkbox>
        <LabelText style={labelStyle} font={secondaryFontRaw}>
          {label}
        </LabelText>
      </Label>
    )
  },
)

const Checkbox = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 30,
  width: 30,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: Colors.grey25,
  backgroundColor: Colors.white,
  marginRight: 15,
  cursor: 'pointer',
})

const Label = styled.label({
  display: 'flex',
  cursor: 'pointer',

  'input:not(:checked) ~ .check > svg': {
    display: 'none',
  },
})

const LabelText = styled.span(({ font }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...fontPositionAdjustments[font],
}))

const StyledInput = styled.input({
  display: 'none',
})
