import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import OfferingsList from './OfferingsList'
import { BAKERY_OFFERINGS } from './Queries'
import { CardBackground } from 'web/styles/shared'
import { Colors } from 'shared/styles/Colors'
import { FetchError } from 'shared/components/FetchError'
import { FixedWidthContainer } from 'web/styles/responsive'
import { MainSubtitle } from './Details'
import { PresaleItem } from './PresaleItem'
import {
  showCakeFlavorsScreen,
  showCookieFlavorsScreen,
  showIcingScreen,
} from 'shared/helpers/showBakedGoodsHelpers'
import { Spinner } from 'shared/components/Spinner'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const Offerings = () => {
  const { isMdUp } = useResponsiveLayout()
  const { slug, categorySlug } = useParams()
  const navigate = useNavigate()
  const { primaryColor, backgroundColor, primaryFont } = useThemeContext()

  const { data, loading, error, refetch } = useQuery(BAKERY_OFFERINGS, { variables: { slug } })

  const bakery = useMemo(() => data?.bakery, [data])
  const category = useMemo(
    () => bakery?.categories?.find((c) => c.slug === categorySlug),
    [bakery, categorySlug],
  )

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  if (!category) return <Navigate to={`/b/${slug}`} />

  const {
    bakery: { bakedGoods, icings, cakeFlavors, cookieFlavors, customSections, currency },
  } = data || {}

  const navigateItemDetails = (productSlug) => {
    navigate(`/b/${slug}/products/${productSlug}`)
  }

  const renderCustomSections = () =>
    customSections?.map((item) => {
      const listProps = {
        title: item.title,
        primaryColor,
        backgroundColor,
        key: item.id,
        primaryFont,
      }

      if (
        (!item.category && item.kind === 'itemized' && categorySlug === 'offerings') ||
        (item.category?.slug === categorySlug && item.kind === 'itemized')
      )
        return <OfferingsList array={item.choices} {...listProps} />

      if (item.category?.slug === categorySlug && item.kind === 'text_box')
        return <OfferingsList text={item.text} {...listProps} />

      return null
    })

  return (
    <Container>
      <MainColumn isMdUp={isMdUp}>
        <MainSubtitle $style={{ marginBottom: 20 }} font={primaryFont}>
          {category.name}
        </MainSubtitle>
        <Card>
          {category.default && (
            <>
              <OfferingsList
                array={bakedGoods}
                title="Offerings"
                primaryColor={primaryColor}
                backgroundColor={backgroundColor}
                primaryFont={primaryFont}
                defaultExpanded
              />

              {showIcingScreen(bakery) && (
                <OfferingsList
                  array={icings}
                  title="Icings"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}

              {showCakeFlavorsScreen(bakery) && (
                <OfferingsList
                  array={cakeFlavors}
                  title="Cake Flavors"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}

              {showCookieFlavorsScreen(bakery) && (
                <OfferingsList
                  array={cookieFlavors}
                  title="Cookie Flavors"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}
            </>
          )}

          <PresaleItemsContainer isMdUp={isMdUp}>
            {category.offerings.map((presaleItem) => (
              <PresaleItem
                item={presaleItem}
                key={presaleItem.id}
                onClick={() => navigateItemDetails(presaleItem?.slug)}
                currency={currency}
              />
            ))}
          </PresaleItemsContainer>

          {renderCustomSections()}
        </Card>
      </MainColumn>
    </Container>
  )
}
const Container = styled(FixedWidthContainer)`
  margin-bottom: 40px;
  padding-top: 5px;
  border-radius: 4px;
  background-color: ${Colors.white};
`

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`

const PresaleItemsContainer = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${isMdUp ? 'normal' : 'space-between'};
`,
)

const Section = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 70px 20px 55px;
  }
`

const Card = styled(Section)({
  ...CardBackground,
  boxShadow: 'none',
  marginBottom: 20,
})
