import AriaModal from 'react-aria-modal'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { EnterNumber } from './EnterNumber'
import { MediaQueries } from 'web/styles/responsive'
import { VerifyNumber } from './VerifyNumber'

export const MobileNumberModal = React.memo(() => {
  const [verifying, setVerifying] = useState(null)
  return (
    <AriaModal
      titleText="Mobile number"
      underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
      underlayClickExits={false}
      escapeExits={false}
      verticallyCenter
      focusDialog={true}
    >
      <Modal>
        {verifying ? (
          <VerifyNumber setVerifying={setVerifying} mobileNumber={verifying} />
        ) : (
          <EnterNumber setVerifying={setVerifying} />
        )}
      </Modal>
    </AriaModal>
  )
})

const Modal = styled.div`
  background-color: ${Colors.white};
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 40px);
  position: relative;
  margin: 0 20px;

  @media ${MediaQueries.mdUp} {
    width: 450px;
    margin: 0 auto;
  }
`
