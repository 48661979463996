import Bugsnag from 'shared/services/Bugsnag'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

import { Configuration } from './Configuration'
import { customFetch } from './customFetch'
import { Platform } from './Platform'

let store = null
export const setStore = (s) => (store = s)

const cache = new InMemoryCache()

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
  },
}

const setHeaders = new ApolloLink((operation, forward) => {
  const headers = {}
  if (global?.appVersion) headers['x-app-version'] = global.appVersion

  const jwt = store.getState().account.jwt
  if (
    jwt &&
    !['CreateSession', 'CreateUser', 'RequestPasswordReset', 'VerifyServiceAccount'].includes(
      operation.operationName,
    )
  )
    headers.authorization = `bearer ${jwt}`

  headers.accept = 'application/json'
  operation.setContext({ headers })
  return forward(operation)
})

const responseLogger = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    if (operation.operationName !== 'RefreshToken') {
      console.log(
        operation.operationName,
        operation.variables,
        operation.getContext().response.status,
        result,
      )
    }
    return result
  })
})

const retryLink = new RetryLink()

const logErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // Don't report any network errors to Bugsnag
  if (networkError?.message) return

  // Don't report any 404 errors on the Web. There should not be 404s in the app, so we should still log those.
  if (
    Configuration.isWeb &&
    graphQLErrors?.some(
      (e) => e?.message?.startsWith("can't find record") || e?.message?.startsWith("Couldn't find"),
    )
  )
    return

  const error = `[GraphQL Error] ${operation.operationName}`

  Bugsnag.notify(new Error(error), (report) => {
    report.groupingHash = error

    report.addMetadata('graphql', {
      operationName: operation.operationName,
      query: operation.query?.loc?.source?.body,
      variables: operation.variables,
      graphQLErrors,
    })
  })
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    setHeaders,
    retryLink,
    responseLogger,
    logErrorLink,
    new HttpLink({
      uri: Configuration.graphqlUrl,
      fetch: (uri, options) => customFetch(uri, options, store),
    }),
  ]),
  cache,
  defaultOptions,
  connectToDevTools: Platform.environment !== 'production',
})
