import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { PAGES } from './constants'
import { REQUEST_ORDER } from 'shared/queries/orders'
import { Spinner } from 'shared/components/Spinner'

export const NewOrderRequestPage = () => {
  const [order, setOrder] = useState()

  const { bakeryId } = useParams()
  const { state } = useLocation()

  const completionDate = state?.completionDate

  const [requestOrder] = useMutation(REQUEST_ORDER, {
    onCompleted: (data) => {
      setOrder(data.requestOrder.order)
    },
  })

  useEffect(() => {
    requestOrder({
      variables: {
        input: {
          bakeryId,
          completionDate,
        },
      },
    })
  }, [bakeryId, completionDate, requestOrder])

  if (order) return <Navigate to={`/order-request/${order.id}/${PAGES[0].name}`} state={state} />
  return <Spinner page />
}
