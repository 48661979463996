import React, { useState } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { BakeryPageLayout } from 'web/components/BakeryPageLayout'
import { Configuration } from 'shared/services/Configuration'
import { Header } from 'web/pages/OrderRequest/styles'
import { ReviewFormComponent } from './ReviewForm'
import { ReviewItem } from '../Reviews'
import { Spinner } from 'shared/components/Spinner'
import { useBakeryRedirect } from 'web/hooks/useBakeryRedirect'

const ogTitle = 'How was your experience? 😊'
const description = 'Please leave a review'

export const ReviewForm = () => {
  const { orderNumber } = useParams()
  const navigate = useNavigate()
  const onCompleted = useBakeryRedirect()

  const { data, loading, refetch } = useQuery(ORDER_DETAILS_QUERY, {
    variables: { orderNumber },
    onCompleted: (_data) => onCompleted(_data.order),
  })

  const [showNotice, setShowNotice] = useState(false)

  if (loading) return <Spinner page />
  const order = data?.order
  const user = order?.bakery?.user

  const review = order?.review
  const name = order?.customer?.name.split(' ')[0]
  const logo = user.avatar?.smallUrl || `${Configuration.apiUrl}/logo-white-bg-og-v1.png`

  const handleSuccess = () => {
    setShowNotice(true)
    refetch()
  }

  if (review)
    return (
      <BakeryPageLayout user={user}>
        <Container>
          {showNotice && <Notice>Thank you for leaving a review!</Notice>}
          <ReviewItem review={review} />
        </Container>
      </BakeryPageLayout>
    )

  if (data?.order.state !== 'complete') return navigate(`/b/${data?.order.bakery.slug}`)

  return (
    <BakeryPageLayout user={user}>
      <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:title" content={ogTitle} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={logo} />
      </Helmet>
      <Container>
        <ReviewFormComponent customerName={name} onSuccess={handleSuccess} />
      </Container>
    </BakeryPageLayout>
  )
}

const Container = styled.div({
  padding: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
})

const Notice = styled(Header)({
  fontSize: 40,
  marginBottom: 80,

  '@media (max-width: 767px)': {
    fontSize: '3rem',
    marginBottom: 40,
  },
})

const ORDER_DETAILS_QUERY = gql`
  query OrderDetails($orderNumber: String) {
    order(orderNumber: $orderNumber) {
      customer {
        name
      }
      state
      review {
        comments
        rating
        title
        name
        reviewDate
      }
      bakery {
        slug
        status
        user {
          subscriptionTier
          avatar {
            smallUrl
          }
        }
      }
    }
  }
`
