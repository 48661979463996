import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Colors } from 'shared/styles/Colors'
import { formatCents } from 'shared/utils/currency'
import { HelpText } from 'web/pages/OrderRequest/styles'
import { MultiLineText } from 'web/components/form'
import { selectBakery } from 'shared/reducers/account'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

export const PresaleItem = ({
  item: { image, name, priceCents, priceType, description },
  onClick,
  currency,
}) => {
  const { isSmUp } = useResponsiveLayout()
  const bakery = useSelector(selectBakery)

  const itemPrice = formatCents(priceCents, currency)
  const pricePrefix = priceType === 'variable' ? 'Starting at ' : ''

  const imageUrl = useMemo(() => image || bakery?.user?.avatar?.largeUrl, [image, bakery])

  return (
    <PresaleItemContainer onClick={onClick} isSmUp={isSmUp}>
      <PresaleItemImage src={imageUrl} alt={name} isSmUp={isSmUp} />

      <NameAndPrice isSmUp={isSmUp}>
        <PresaleItemName isSmUp={isSmUp}>{name}</PresaleItemName>
        {description && <MultiLineText Container={Description}>{description}</MultiLineText>}
        {priceType !== 'noPrice' && (
          <PresaleItemPrice isSmUp={isSmUp}>{pricePrefix + itemPrice}</PresaleItemPrice>
        )}
      </NameAndPrice>
      <HelpTextContainer>
        <HelpText>click for description</HelpText>
      </HelpTextContainer>
    </PresaleItemContainer>
  )
}

const PresaleItemContainer = styled.div(
  ({ isSmUp }) => `
    display: flex;
    flex-direction: column;
    margin: ${isSmUp ? '0 15px 40px 15px' : '0 0 40px 0'};
    border: 1px solid ${Colors.grey25};
    border-radius: 5px;
    overflow: hidden;
    width: ${isSmUp ? 'auto' : 'calc(50% - 6px)'};

    &:nth-child(odd) {
      margin: ${isSmUp ? '0 15px 40px 15px' : '0 12px 40px 0'};
    }
  `,
)

const NameAndPrice = styled.div(
  ({ isSmUp }) => `
    width: ${isSmUp ? '200px' : '100%'};
    display: flex;
    flex: 1;
    background-color: white;
    flex-direction: column;
    padding: 20px 0px 10px 15px;
  `,
)

const PresaleItemImage = styled.img(
  ({ isSmUp }) => `
    width: ${isSmUp ? '200px' : '100%'};
    height:${isSmUp ? '200px' : '60%'};
    object-fit: cover;
  `,
)

const PresaleItemName = styled.div(
  ({ isSmUp }) => `
    font-size: ${isSmUp ? '16px' : '3.5vw'};
    font-weight: 600;
    margin-bottom: ${isSmUp ? '15px' : '5px'};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

const PresaleItemPrice = styled.div(
  ({ isSmUp }) => `
    font-size: ${isSmUp ? '14px' : '3vw'};
    font-weight: 500;
  `,
)

const Description = styled.div(
  ({ isSmUp }) => `
    font-size: 16px;
    font-weight: 400;
    color: ${Colors.grey50};
    margin-right: 10px;
    display: ${isSmUp ? '-webkit-box' : 'none'};
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

const HelpTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 5,
})
