import Bugsnag from 'shared/services/Bugsnag'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

import * as Cross from 'shared/components/Cross'
import {
  addImage,
  removeImage,
  replaceImage,
  setImages,
  useGalleryReducer,
} from 'shared/hooks/useGalleryReducer'
import { Colors } from 'shared/styles/Colors'
import { directUpload, imageInterface } from 'shared/utils/directUpload'
import { Gallery } from 'shared/components/Gallery/Gallery'
import { Header } from 'web/pages/OrderRequest/styles'
import { InfoModal } from 'web/components/InfoModal'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { styler } from 'shared/utils/styler'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const MAX_IMAGES = 5

const transformImagesForUpload = (images) => {
  return images.map((image) => ({
    key: image.key,
    signedId: image.signedId,
  }))
}

export const Pictures = ({ order, setOrder }) => {
  const { width } = useWindowDimensions()
  const [state, dispatch] = useGalleryReducer()
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    dispatch(setImages(order.inspirationPhotos))
  }, [dispatch, order])

  const { loading, updateOrder } = useUpdateOrder({ order, setOrder })
  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({
      afterUpdate: navigateToNextScreen,
      input: { inspirationPhotos: transformImagesForUpload(state.images) },
    })
  }

  const dispatchRemoveImage = useCallback(
    (id) => {
      dispatch(removeImage(id))
    },
    [dispatch],
  )

  const uploadImage = useCallback(
    async (image) => {
      const tempId = uuidv4()
      const url = URL.createObjectURL(image)

      dispatch(addImage({ id: tempId, url, loading: true }))

      try {
        const fileInterface = await imageInterface(image)
        const directUploadJson = await directUpload(fileInterface)
        const { signedId, key } = directUploadJson

        dispatch(replaceImage({ id: tempId, image: { id: tempId, key, url, signedId } }))
      } catch (e) {
        console.error('error picking image', e)
        Bugsnag.notify(e)
        dispatchRemoveImage(tempId)
      }
    },
    [dispatch, dispatchRemoveImage],
  )

  const uploadImages = useCallback(
    (images) => {
      let notify = false
      const currentImageCount = state.images?.length || 0

      if (currentImageCount + images?.length > MAX_IMAGES) {
        images = images.slice(0, MAX_IMAGES - currentImageCount)
        notify = true
      }

      images.forEach((image) => uploadImage(image))
      if (notify) alert(`You may upload a maximum of ${MAX_IMAGES} images.`)
    },
    [state.images, uploadImage],
  )

  const onDrop = useCallback(
    (files) => {
      document.activeElement.blur()
      const acceptedFiles = files.filter((f) => f.type.match(/^image\//))
      if (acceptedFiles.length) uploadImages(acceptedFiles)
    },
    [uploadImages],
  )
  const { getRootProps, isDragActive } = useDropzone({ onDrop })
  const instagramTroubleUpload = () => {
    if (navigator.userAgent.match(/instagram/i)) {
      return (
        <>
          {showModal && <InfoModal onClose={() => setShowModal(false)} />}
          <TextContainer>
            <TroubleText>Having trouble uploading photos?</TroubleText>
            <LinkText onClick={() => setShowModal(!showModal)}>Click here</LinkText>
          </TextContainer>
        </>
      )
    } else {
      return <div />
    }
  }

  return (
    <OrderRequestLayout
      nextDisabled={loading || state.images.some((i) => i.loading)}
      onNext={onNext}
      nextButtonText={state.images?.length > 0 ? 'Next' : 'Skip'}
      order={order}
    >
      <Header>Do you have picture(s) for inspiration?</Header>
      <GalleryContainer {...getRootProps()}>
        {isDragActive && <Highlight />}
        <Gallery
          galleryWidth={width - 40 > 500 ? 500 : width - 40}
          addImages={uploadImages}
          itemProps={{
            removeImage: dispatchRemoveImage,
          }}
          maxPlaceholders={5}
          data={state.images.map((image) => ({
            id: image.id,
            url: image.thumbnailUrl || image.url,
            loading: image.loading,
          }))}
        />
      </GalleryContainer>
      {instagramTroubleUpload()}
    </OrderRequestLayout>
  )
}

const GalleryContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  outline: 0,
})

const Highlight = styler(Cross.View)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  border: `4px solid ${Colors.blue}`,
})

const TroubleText = styled.text({
  fontWeight: 'bold',
  color: Colors.black,
})
const LinkText = styled.text({
  fontWeight: 'bold',
  color: Colors.aqua,
  fontSize: 22,
  textDecoration: 'underline',
  textUnderlineOffset: 2,
  textAlign: 'left',
  cursor: 'pointer',
})

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: 30,
})
