import { gql } from '@apollo/client'

export const BUTTON_PREFERENCES_FRAGMENT = gql`
  fragment ButtonPreferencesFragment on ButtonPreferences {
    availabilityHidden
    actionButtonName
    storefrontActionHidden
  }
`

export const UPDATE_BUTTON_PREFERENCES = gql`
  ${BUTTON_PREFERENCES_FRAGMENT}

  mutation UpdateButtonPreferences($input: ButtonPreferencesInput!) {
    updateButtonPreferences(input: $input) {
      buttonPreferences {
        ...ButtonPreferencesFragment
      }
      errors {
        messages
        path
      }
    }
  }
`
