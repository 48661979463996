import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { Header } from 'web/pages/OrderRequest/styles'
import { MediaQueries } from 'web/styles/responsive'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { SpecHints } from 'shared/utils/SpecHints'
import { StyledTextArea } from 'web/components/form/TextAreaField'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const Theme = ({ order, setOrder }) => {
  const [orderItems, setOrderItems] = useState(
    order.orderItems.map((oi) => ({
      offeringId: oi.offeringId,
      quantity: oi.quantity,
      unit: oi.unit,
      spec: oi.spec || '',
    })),
  )
  const valid = orderItems.every((oi) => oi.spec.length)
  const { loading, updateOrder } = useUpdateOrder({ order, setOrder })
  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({ afterUpdate: navigateToNextScreen, input: { orderItems } })
  }

  const updateSpec = (offeringId, spec) => {
    setOrderItems(orderItems.map((oi) => (oi.offeringId === offeringId ? { ...oi, spec } : oi)))
  }

  return (
    <OrderRequestLayout nextDisabled={!valid || loading} onNext={onNext} order={order}>
      <StyledHeader>Order specifications</StyledHeader>

      {orderItems.map((orderItem) => {
        const offering = {
          ...order.bakery.selectedBakedGoods.find((o) => o.id === orderItem.offeringId),
          ...order.bakery.selectedPresaleItems.find((o) => o.id === orderItem.offeringId),
          ...order.bakery.selectedMenuItems.find((o) => o.id === orderItem.offeringId),
        }
        return (
          <SpecContainer key={orderItem.offeringId}>
            <SpecTitle>{offering.name}</SpecTitle>
            <StyledInput
              onChange={(evt) => updateSpec(orderItem.offeringId, evt.target.value)}
              value={orderItem.spec}
              placeholder={SpecHints[offering.name] || SpecHints.Default}
            />
          </SpecContainer>
        )
      })}
    </OrderRequestLayout>
  )
}

export const StyledInput = styled(StyledTextArea)({
  flex: 1,
  minHeight: 100,
  maxHeight: 150,
  marginTop: 5,
})

export const StyledHeader = styled(Header)({
  [`@media ${MediaQueries.mdUp}`]: {
    padding: '0 80px',
  },
})

export const SpecContainer = styled.div({
  marginBottom: 20,
})

export const SpecTitle = styled.div({
  fontWeight: 'bold',
})

export const Hint = styled.div({
  color: Colors.grey50,
  fontSize: '1.6rem',
})
