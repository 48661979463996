import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { GET_ORDER } from 'shared/queries/orders'
import { useQuestionCounter } from 'web/hooks/useQuestionCounter'

const OrderContext = createContext()

export const OrderProvider = ({ children }) => {
  const { orderId } = useParams()
  const location = useLocation()

  const [order, setOrder] = useState(null)

  const currentPage = location.pathname.split('/').pop()
  const { enableOrderSpecifications } = order?.bakery || {}
  const [orderOfferingIds, orderCategoryIds] = useMemo(() => {
    const offeringIds = []
    const categoryIds = []

    order?.orderItems.forEach((oi) => {
      offeringIds.push(oi.offeringId)
      categoryIds.push(oi.categoryId)
    })
    return [new Set(offeringIds), new Set(categoryIds)]
  }, [order])

  const skipDueDate = useMemo(
    () => order?.orderItems.every((oi) => oi.offering.dueDateDisabled),
    [order?.orderItems],
  )

  const forOfferings = useCallback(
    (offerings) => {
      if (!offerings.length) return

      const offeringIds = offerings.map((o) => o.id)
      return offeringIds.some((id) => orderOfferingIds.has(id))
    },
    [orderOfferingIds],
  )

  const forCategories = useCallback(
    (categories) => {
      if (!categories.length) return

      const categoryIds = categories.map((c) => c.id)
      return categoryIds.some((id) => orderCategoryIds.has(id))
    },
    [orderCategoryIds],
  )

  const customQuestions = useMemo(() => {
    const items = order?.bakery?.customQuestions

    if (!items?.length || currentPage === 'details') return []

    return items.filter(
      (cq) =>
        (cq.offerings.length === 0 && cq.categories.length === 0) ||
        forOfferings(cq.offerings) ||
        forCategories(cq.categories),
    )
  }, [order, forOfferings, forCategories, currentPage])

  const customQuestionsLength = customQuestions?.length || 0
  const dueDateOffset = skipDueDate ? 0 : 1
  const specificationsOffset = enableOrderSpecifications ? 0 : 1

  const { questionCounter, setQuestionCounter } = useQuestionCounter({
    customQuestionsLength,
    dueDateOffset,
    specificationsOffset,
    currentPage,
  })

  const { loading, error, refetch } = useQuery(GET_ORDER, {
    variables: { id: orderId },
    onCompleted: (data) => setOrder(data.order),
  })

  const value = {
    currentPage,
    customQuestions,
    loading: order?.bakery?.status === 'expired' ? true : loading,
    error,
    refetch,
    order,
    questionCounter,
    setQuestionCounter,
    skipDueDate,
    setOrder,
  }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}

export const useOrderContext = () => useContext(OrderContext)
