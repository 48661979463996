import { gql } from '@apollo/client'

const OfferingFragment = gql`
  fragment OfferingFragment on Offering {
    allowHalfDozen
    allowMaxQuantity
    categoryId
    categoryName
    position
    description
    dozenOnly
    enableInspirationPhotos
    featured
    enableInstantCheckout
    hidden
    selected
    id
    image
    images {
      id
      key
      thumbnailUrl
      featured
      fullUrl
    }
    maxQuantity
    minQuantity
    name
    priceCents
    priceType
    updatedAt
    stock
  }
`

const CategoryFragment = gql`
  ${OfferingFragment}

  fragment CategoryFragment on Category {
    id
    slug
    name
    default
    dueDateDisabled
    offeringsCount
    offerings(selected: true) {
      ...OfferingFragment
    }
    updatedAt
  }
`

const CurrentBakery = gql`
  ${CategoryFragment}

  fragment CurrentBakeryFragment on Bakery {
    id
    name
    bio
    defaultInvoiceMessage
    defaultPickupAddress
    defaultAddressType
    bakesyUrl
    capabilitiesAndPricing
    firstOrderDate
    location {
      id
      name
      countryCode
      region {
        country {
          id
          name
        }
        name
      }
    }
    currency {
      flagUrl
      id
      name
      symbol
    }
    enablePaymentReminders
    enablePickupReminders
    enableAnnouncementBanner
    enableOrderSpecifications
    enableHalfDozens
    instantCheckoutMethod
    status
    blockedDates
    futureBlockMonths
    leadTimeBlockEnabled
    leadTimeUnit
    leadTimeValue
    minimumOrderInCents
    ordersPaused
    dailyOrderLimit
    requiredDepositPercentage
    statusHeadline
    slug
    categories {
      ...CategoryFragment
    }
    selectedBakedGoods: offerings(
      offeringTypes: [bakedGood, menuItem, presaleItem]
      selected: true
    ) {
      id
      name
      dozenOnly
      allowHalfDozen
      defaultUnit
      offeringType
      minQuantity
      slug
      priceCents
      priceType
      stock
    }
    reviewSummary {
      average
      count
    }
    termsAndConditions
  }
`

const UserSettingsFragment = gql`
  fragment UserSettingsFragment on UserSettings {
    calendarSync
    emailMessages
    emailReminders
    pushNotificationsMessages
    pushNotificationsReminders
    textMessagesMessages
    textMessagesReminders
  }
`

const CurrentUser = gql`
  ${CurrentBakery}
  ${UserSettingsFragment}

  fragment CurrentUserFragment on User {
    id
    name
    email
    mobileNumber
    countryCode
    ablyChannelId
    contactMethod
    subscriptionTier
    avatar {
      smallUrl
      largeUrl
    }
    bakery {
      ...CurrentBakeryFragment
    }
    settings {
      ...UserSettingsFragment
    }
  }
`

const ChatImages = gql`
  fragment ChatImagesFragment on Message {
    images {
      id
      thumbnailUrl
      fullUrl
    }
  }
`

const ChatMessage = gql`
  fragment ChatMessageFragment on Message {
    id
    content
    sentAt
    read
    sender {
      id
      name
      verified
      avatar {
        id
        smallUrl
      }
      bakery {
        id
        name
        slug
        bakesyUrl
      }
    }
    receiver {
      id
      name
      verified
      avatar {
        id
        smallUrl
      }
      bakery {
        id
        name
        slug
        bakesyUrl
      }
    }
  }
`

const CustomSection = gql`
  fragment CustomSectionFragment on CustomSection {
    choices
    id
    kind
    text
    title
    category {
      id
      slug
    }
  }
`

export const Fragments = {
  CategoryFragment,
  CurrentUser,
  CurrentBakery,
  ChatImages,
  ChatMessage,
  OfferingFragment,
  CustomSection,
  UserSettingsFragment,
}
