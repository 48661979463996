import React, { memo } from 'react'
import styled from 'styled-components'
import { formatDistance, parseISO } from 'date-fns'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { fontSizeAdjustments } from 'shared/styles/PremiumThemes'
import { Header } from 'web/pages/OrderRequest/styles'
import { ReviewBars } from 'web/components/reviews/ReviewBars'
import { ReviewStars } from 'web/components/reviews/ReviewStars'
import { Spinner } from 'shared/components/Spinner'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const ReviewItem = memo(({ review }) => (
  <ReviewItemContainer>
    <ReviewItemTextWrap>
      <Text size={30} bold>
        {review.title}
      </Text>
      <Text>{review.name}</Text>
    </ReviewItemTextWrap>

    <ReviewItemStarsWrap>
      <ReviewStars rating={review.rating} type="reviews" small />
      <Text size={14} color={Colors.grey50}>
        {formatDistance(parseISO(review.reviewDate), new Date(), { addSuffix: true })}
      </Text>
    </ReviewItemStarsWrap>
    <div>{review.comments}</div>
  </ReviewItemContainer>
))

export const Reviews = memo(() => {
  const { layoutHeight } = useWindowDimensions()
  const { slug } = useParams()
  const { data, loading } = useQuery(REVIEWS, { variables: { slug } })

  const reviews = data?.bakery.reviews || []
  const reviewSummary = data?.bakery.reviewSummary || {}

  if (loading) return <Spinner page />

  return (
    <Container minHeight={layoutHeight}>
      <HeaderTitle>Ratings & Reviews</HeaderTitle>
      <HeaderSubtitle>Overall Rating</HeaderSubtitle>
      <StarsWrapper>
        <ReviewStars rating={reviewSummary.average} type="reviews" />
        {reviews.length} Rating{reviews.length > 1 || reviews.length === 0 ? 's' : ''}
      </StarsWrapper>
      <ReviewBars style={{ marginBottom: 20 }} reviews={reviews} />

      <Separator />

      <div>
        {reviews.map((review) => (
          <div key={review.id}>
            <ReviewItem review={review} />
            {reviews.length > 1 && <Separator />}
          </div>
        ))}
      </div>
    </Container>
  )
})

const Container = styled(FixedWidthContainer)(({ minHeight }) => ({
  paddingBottom: 40,
  backgroundColor: Colors.white,
  minHeight: minHeight - 10,
}))

const HeaderTitle = styled(Header)(() => {
  const { primaryFont } = useThemeContext()

  return {
    fontSize: `${2.5 + (fontSizeAdjustments[primaryFont] || 0)}rem`,
    marginBottom: 10,
    '@media (max-width: 767px)': {
      textAlign: 'left',
    },
  }
})

const HeaderSubtitle = styled.h2(() => {
  const { primaryFont } = useThemeContext()

  return {
    fontSize: `${1.8 + (fontSizeAdjustments[primaryFont] || 0)}rem`,
    marginBottom: 20,
    textAlign: 'left',
  }
})

const StarsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  marginTop: 20,
  marginBottom: 20,
})

const Text = styled.span(({ color, size, bold }) => ({
  color,
  fontSize: size,
  ...(bold ? { fontWeight: 'bold' } : {}),
}))

const Separator = styled.div({
  height: 1,
  flexShrink: 0,
  backgroundColor: Colors.grey10,
  marginTop: 20,
  marginBottom: 20,
})

const ReviewItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',

  [`@media ${MediaQueries.mdUp}`]: {
    width: '50%',
  },
})

const ReviewItemTextWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 10,
})

const ReviewItemStarsWrap = styled(ReviewItemTextWrap)({
  justifyContent: 'flex-start',
  gap: 16,
})

const REVIEWS = gql`
  query getBakeryReviews($slug: String) {
    bakery(slug: $slug) {
      reviews {
        id
        comments
        rating
        title
        name
        reviewDate
      }
      reviewSummary {
        count
        average
      }
    }
  }
`
