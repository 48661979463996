import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

import { bakeryPath } from 'shared/utils/bakeries'
import { Colors } from 'shared/styles/Colors'
import { toRawMobileNumber } from 'shared/utils/phone'
import { useThemeContext } from 'web/contexts/ThemeContext'

const MenuCategory = React.memo(({ bakery, category }) => {
  const { primaryColor } = useThemeContext()

  const isDefault = useMemo(() => category.default, [category])

  const customSections = useMemo(
    () =>
      bakery.customSections?.filter((section) =>
        isDefault
          ? !section.category && section.kind === 'itemized' && section.choices.length > 0
          : section?.category?.slug === category.slug,
      ) || [],
    [bakery.customSections, category.slug, isDefault],
  )

  const hasProducts = useMemo(() => category.offerings?.length > 0, [category.offerings])
  const hasBakedGoods = useMemo(() => bakery.bakedGoods?.length > 0, [bakery.bakedGoods])
  const hasCustomSections = useMemo(() => customSections?.length > 0, [customSections])

  if (isDefault && !hasProducts && !hasBakedGoods && !hasCustomSections) return null
  if (!isDefault && !hasProducts && !hasCustomSections) return null

  return (
    <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/category/${category.slug}`}>
      {category.name}
    </MenuLink>
  )
})

/* TODO: should be renamed to SidebarContent */
export const HeaderContent = React.memo(({ bakery, onClick = undefined }) => {
  const location = useLocation()
  const { primaryColor } = useThemeContext()

  const menuRef = useRef(null)

  useEffect(() => {
    if (menuRef.current) menuRef.current.scrollTop = 0
  }, [location.pathname])

  const contactLink = useMemo(() => {
    switch (bakery?.user?.contactMethod) {
      case 'email':
        return `mailto:${bakery.user.email}`
      case 'call':
        return `tel:+${toRawMobileNumber(bakery.user.formattedMobileNumber)}`
      case 'sms':
        return `sms:+${toRawMobileNumber(bakery.user.formattedMobileNumber)}`
      default:
        return null
    }
  }, [bakery?.user])

  if (!bakery) return null

  return (
    <Container onClick={onClick}>
      {bakery.categories?.map((category) => (
        <MenuCategory bakery={bakery} category={category} key={category.slug} />
      ))}

      {!!bakery.galleryImage && (
        <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/gallery`}>
          Gallery
        </MenuLink>
      )}

      {!!bakery.faqs?.length && (
        <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/faqs`}>
          FAQs
        </MenuLink>
      )}

      {!!bakery?.reviewSummary?.count && (
        <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/reviews`}>
          Reviews
        </MenuLink>
      )}

      <Contact $primaryColor={primaryColor} href={contactLink}>
        Contact
      </Contact>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  pointer-events: auto;
  z-index: 1000;
  & a {
    color: ${Colors.black};
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0;
  }
`

const MenuLink = styled(Link)(
  ({ $primaryColor }) => `
    text-align: center;
    z-index: 1001;
    :hover {
      color: ${$primaryColor};
    }
  `,
)

const Contact = styled.a(
  ({ $primaryColor }) => `
    text-align: center;
    :hover {
      color: ${$primaryColor};
    }
  `,
)
