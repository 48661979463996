import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Alerts } from './Alerts'
import { Header } from './Header/Header'
import { setBakery } from 'shared/reducers/account'
import { useBakeryRedirect } from 'web/hooks/useBakeryRedirect'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const OrderPageLayout = ({ children }) => {
  const dispatch = useDispatch()
  const { orderNumber, orderId } = useParams()
  const { secondaryFont } = useThemeContext()
  const redirect = useBakeryRedirect()

  const { data } = useQuery(ORDER_DETAILS_QUERY, {
    onCompleted: (_data) => redirect(_data.order),
    variables: { orderNumber, id: orderId },
    skip: !orderNumber && !orderId,
  })

  const bakery = data?.order?.bakery

  useEffect(() => {
    if (bakery) dispatch(setBakery(bakery))
  }, [bakery, dispatch])

  return (
    <Container fontFamily={secondaryFont}>
      <Alerts />
      <Header alt={!!orderNumber || !!orderId} bakery={bakery} />
      {children}
    </Container>
  )
}

const Container = styled.div(({ fontFamily }) => ({
  fontFamily,
}))

const ORDER_DETAILS_QUERY = gql`
  query OrderDetails($id: ID, $orderNumber: String) {
    order(id: $id, orderNumber: $orderNumber) {
      bakery {
        enableAnnouncementBanner
        name
        slug
        status
        announcementBanner {
          backgroundColor
          fontColor
          message
        }
        user {
          avatar {
            largeUrl
          }
        }
      }
    }
  }
`
