import { gql } from '@apollo/client'

import { Fragments } from 'shared/constants/Fragments'

export const BAKERY_OFFERINGS = gql`
  ${Fragments.CustomSection}

  query getBakery($slug: String) {
    bakery(slug: $slug) {
      id
      currency {
        flagUrl
        id
        name
        symbol
      }
      bakedGoods: offerings(offeringType: bakedGood, selected: true) {
        id
        name
      }
      categories {
        id
        name
        default
        dueDateDisabled
        slug
        offeringsCount
        offerings(selected: true, hidden: false) {
          id
          name
          priceCents
          image
          images {
            id
            key
            thumbnailUrl
            fullUrl
          }
          description
          priceType
          hidden
          slug
        }
        updatedAt
      }
      icings: offerings(offeringType: icing, selected: true) {
        id
        name
      }
      cakeFlavors: offerings(offeringType: cakeFlavor, selected: true) {
        id
        name
      }
      cookieFlavors: offerings(offeringType: cookieFlavor, selected: true) {
        id
        name
      }
      customSections {
        ...CustomSectionFragment
      }
    }
  }
`

export const BAKERY_PRESALE = gql`
  query getBakery($slug: String) {
    bakery(slug: $slug) {
      id
      currency {
        flagUrl
        id
        name
        symbol
      }
      presaleItems: offerings(offeringType: presaleItem, selected: true, hidden: false) {
        id
        name
        image
        priceCents
        description
        priceType
      }
    }
  }
`

export const BAKERY_FAQ = gql`
  query getBakery($slug: String) {
    bakery(slug: $slug) {
      faqs {
        id
        title
        description
      }
      premiumTheme {
        primaryFont
      }
    }
  }
`

export const BAKERY_ITEM_DETAILS = gql`
  query GetBakeryOffering($slug: String!, $offeringSlug: String!) {
    bakery(slug: $slug) {
      id
      ordersPaused
      currency {
        flagUrl
        id
        name
        symbol
      }
    }
    offering(bakerySlug: $slug, offeringSlug: $offeringSlug) {
      id
      name
      image
      images {
        id
        key
        thumbnailUrl
        fullUrl
      }
      priceCents
      description
      priceType
      slug
      stock
      dozenOnly
    }
  }
`
