import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { Colors } from 'shared/styles/Colors'
import { Fonts } from 'shared/styles/Typography'
import { GET_THEME } from 'shared/queries/theme'
import { selectBakery } from 'shared/reducers/account'

const ThemeContext = createContext()

const { defaultPrimary, defaultBackground } = Colors
const defaults = {
  bakery: {
    buttonPreferences: {
      actionButtonName: 'Request Order',
      availabilityHidden: false,
      storefrontActionHidden: false,
    },
    premiumTheme: {
      dividerVariant: 0,
      buttonVariant: 'outline100',
      primaryFont: Fonts.DefaultPrimary,
      secondaryFont: Fonts.DefaultSecondary,
      facebookHandle: null,
      instagramHandle: null,
      tiktokHandle: null,
    },
    storefrontAppearance: { primaryColor: defaultPrimary, background: defaultBackground },
  },
}

export const ThemeProvider = ({ children }) => {
  const { slug, id } = useSelector(selectBakery) || {}

  const { data = defaults, loading: themeLoading } = useQuery(GET_THEME, {
    variables: { slug, id },
    skip: !slug && !id,
  })

  const {
    bakery: { buttonPreferences, premiumTheme, storefrontAppearance },
  } = data
  const {
    bakery: {
      buttonPreferences: defaultPrefs,
      premiumTheme: defaultPrem,
      storefrontAppearance: defaultAppearance,
    },
  } = defaults

  const { availabilityHidden, actionButtonName, storefrontActionHidden } =
    buttonPreferences || defaultPrefs
  const {
    dividerVariant,
    buttonVariant,
    // We don't need to add fallback to primaryFont here because it will break
    // font size adjustment
    primaryFont,
    secondaryFont: _secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
  } = premiumTheme || defaultPrem
  const {
    image: coverImage,
    primaryColor,
    background: backgroundColor,
  } = storefrontAppearance || defaultAppearance

  const secondaryFont = _secondaryFont.includes('Carlito')
    ? Fonts.DefaultSecondary
    : `${_secondaryFont}, ${Fonts.Fallback}`

  const theme = {
    dividerVariant,
    buttonVariant,
    primaryFont,
    secondaryFont,
    secondaryFontRaw: _secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
    coverImage,
    primaryColor,
    backgroundColor,
    availabilityHidden,
    actionButtonName,
    storefrontActionHidden,
    themeLoading,
  }

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => useContext(ThemeContext)
export const SessionConsumer = ThemeContext.Consumer
