import React from 'react'
import styled from 'styled-components'

import { addColon } from 'shared/utils/textTransforms'
import { Colors } from 'shared/styles/Colors'
import { isValidUrl } from 'shared/utils/url'

export const Default = ({ paymentMethod }) => {
  const username = paymentMethod.username

  const createLink = () => {
    if (!username) return null

    username.toLowerCase().includes('http') ? username : `https://${username}`
  }

  const link = createLink()

  return (
    <span>
      {paymentMethod.name || paymentMethod.displayType}
      {username && !isValidUrl(username)
        ? addColon(username)
        : link && addColon(<PaymentLink href={link}>{link}</PaymentLink>)}
    </span>
  )
}

const PaymentLink = styled.a({
  color: Colors.blue,
})
