import React from 'react'
import styled from 'styled-components'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors } from 'shared/styles/Colors'
import { HeaderContent } from './HeaderContent'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const Sidebar = ({ Avatar, bakery, isVisible, onClick, isMdUp }) => {
  const { height } = useWindowDimensions()

  return (
    <SidebarContainer $height={height} $isVisible={isVisible} $isMdUp={isMdUp}>
      <Close onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} color={Colors.black} size="lg" />
      </Close>
      <AvatarContainer onClick={onClick}>{Avatar}</AvatarContainer>
      <HeaderContent bakery={bakery} onClick={onClick} />
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div(
  ({ $isVisible, $isMdUp, $height }) => `
    width: ${$isMdUp ? '25%' : '50%'};
    position: fixed;
    z-index: 120;
    right: 0;
    top: 0;
    background: ${Colors.white} ;
    height: ${$height}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: ${$isVisible ? '0' : '-100%'};
    transition: 350ms;
    padding-top: 20px;
  `,
)

const AvatarContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  justify-content: center;
  border-bottom: 1px solid ${Colors.grey25};
  margin-bottom: 30px;
`

const Close = styled.div`
  position: absolute;
  top: 10px;
  left 20px;
`
