import AriaModal from 'react-aria-modal'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { Button } from 'web/components/Button'
import { CANCEL_ORDER_QUERY } from 'shared/queries/orders'
import { Colors } from 'shared/styles/Colors'
import { MediaQueries } from 'web/styles/responsive'
import { Spinner } from 'shared/components/Spinner'
import { useNavigate } from 'react-router-dom'

export const CancelDepositModal = React.memo(
  ({ onDismiss, onComplete, onFail, order, success }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [cancelDepositMutation] = useMutation(CANCEL_ORDER_QUERY)

    const confirmCancel = async () => {
      setLoading(true)
      let cancelError = null

      try {
        const {
          data: {
            cancelDepositRequest: { errors },
          },
        } = await cancelDepositMutation({
          variables: { input: { orderId: order.id } },
        })
        if (errors.length) cancelError = errors[0]
      } catch (e) {
        cancelError = e
      }

      setLoading(false)

      if (cancelError) {
        console.log('error canceling order', cancelError)
        setError('Error while canceling the order.')
      } else {
        order.isInstantCheckout ? navigate(`/b/${order.bakery.slug}`) : onComplete()
      }
    }

    const renderCancel = () => {
      return (
        <div>
          <ModalSubtitle>Are you sure you would like to cancel this order?</ModalSubtitle>
          {loading && <Spinner />}
          <Actions>
            <Button
              button
              color={Colors.white}
              borderColor={Colors.grey75}
              style={{ marginRight: 10, color: Colors.grey75 }}
              onClick={onDismiss}
              disabled={loading}
            >
              Nevermind
            </Button>

            <Button button color={Colors.red} onClick={confirmCancel} disabled={loading}>
              Cancel
            </Button>
          </Actions>
        </div>
      )
    }

    const renderError = () => {
      return (
        <div>
          <ModalSubtitle>{error}</ModalSubtitle>
          <Actions>
            <Button button color={Colors.red} onClick={onFail} disabled={loading}>
              Close it
            </Button>
          </Actions>
        </div>
      )
    }

    const renderSuccess = () => (
      <>
        <ModalSubtitle>
          Please make payment using one of the accepted payment methods below.
        </ModalSubtitle>

        <Actions>
          <Button button color={Colors.green} onClick={onComplete}>
            Got it!
          </Button>
        </Actions>
      </>
    )

    const renderContent = () => {
      if (error) return renderError()
      else if (success) return renderSuccess()
      else return renderCancel()
    }

    return (
      <AriaModal
        titleText="Cancel Order"
        underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
        underlayClickExits={false}
        escapeExits={false}
        verticallyCenter
        focusDialog={true}
      >
        <Modal>{renderContent()}</Modal>
      </AriaModal>
    )
  },
)

const Modal = styled.div`
  background-color: ${Colors.white};
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 40px);
  position: relative;
  margin: 0 20px;

  @media ${MediaQueries.mdUp} {
    width: 450px;
    margin: 0 auto;
  }
`

const ModalSubtitle = styled.span``

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 16px;
`
