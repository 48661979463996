import React, { useMemo } from 'react'
import styled from 'styled-components'
import { endOfDay, isAfter, parseISO } from 'date-fns'
import { minus, plus } from 'shared/utils/number'

import { Colors } from 'shared/styles/Colors'
import {
  Divider,
  Helper,
  Info,
  ItemRow,
  Label,
  Row,
  Strong,
  Value,
} from 'shared/components/Invoice/styles'
import { formatCents } from 'shared/utils/currency'
import { formatDate } from 'shared/utils/dates'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const ScheduleSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()
  const { depositInvoice, finalInvoice } = order

  const totalAmountCents = useMemo(() => {
    if (!depositInvoice) return null

    return order.totalAmountCents ?? order.subtotalAmountCents
  }, [order.totalAmountCents, order.subtotalAmountCents, depositInvoice])

  const finalAmountCents = useMemo(() => {
    if (!depositInvoice) return null

    return minus(totalAmountCents, depositInvoice?.amountInCents)
  }, [totalAmountCents, depositInvoice])

  const totalAmountPaid = useMemo(() => {
    let amount = 0

    if (depositInvoice?.paidAt) amount = plus(amount, depositInvoice?.amountInCents)
    if (finalInvoice?.paidAt) amount = plus(amount, finalAmountCents)

    return amount
  }, [depositInvoice, finalInvoice, finalAmountCents])

  const remainingBalance = useMemo(() => {
    if (!depositInvoice) return null

    return minus(totalAmountCents, totalAmountPaid)
  }, [totalAmountCents, totalAmountPaid, depositInvoice])

  const renderStatus = (invoice, dueDate) => {
    const isPaid = !!invoice?.paidAt
    const isPassed = isAfter(new Date(), endOfDay(parseISO(dueDate)))

    switch (true) {
      case isPaid:
        return (
          <Info $color={Colors.green}>
            <Strong>Paid</Strong>
          </Info>
        )

      case isPassed:
        return (
          <Info $color={Colors.red}>
            <Strong>Overdue</Strong>
          </Info>
        )

      default:
        return <Info>Unpaid</Info>
    }
  }

  if (!show) return null

  return (
    <Container>
      <Row $marginBottom={20}>
        <Strong>Payment schedule</Strong>
      </Row>

      {Number.isInteger(depositInvoice?.amountInCents) && (
        <>
          <ItemRow style={{ alignItems: 'flex-start' }}>
            <Label>Deposit:</Label>

            <AmountContainer>
              <Value>{formatCents(depositInvoice?.amountInCents, order?.bakery?.currency)}</Value>
              {!!depositInvoice?.tipCents && (
                <Value style={{ color: Colors.grey50 }}>
                  {formatCents(depositInvoice?.tipCents, order?.bakery?.currency)} Tip
                </Value>
              )}
            </AmountContainer>
          </ItemRow>

          {order.depositDue && (
            <ItemRow>
              <Helper>Due on {formatDate(order.depositDue)}</Helper>

              {renderStatus(depositInvoice, order.depositDue)}
            </ItemRow>
          )}
        </>
      )}

      {Number.isInteger(depositInvoice?.amountInCents) &&
        Number.isInteger(order.subtotalAmountCents) && (
          <>
            <ItemRow $marginTop={10} style={{ alignItems: 'flex-start' }}>
              <Label>Balance:</Label>

              <AmountContainer>
                <Value>{formatCents(finalAmountCents, order?.bakery?.currency)}</Value>
                {!!finalInvoice?.tipCents && (
                  <Value style={{ color: Colors.grey50 }}>
                    {formatCents(finalInvoice?.tipCents, order?.bakery?.currency)} Tip
                  </Value>
                )}
              </AmountContainer>
            </ItemRow>

            {order.balanceDue && (
              <ItemRow>
                <Helper>Due on {formatDate(order.balanceDue)}</Helper>

                {renderStatus(finalInvoice, order.balanceDue)}
              </ItemRow>
            )}
          </>
        )}

      <Divider $marginTop={20} $marginBottom={20} />

      <ItemRow>
        <Label>Total paid</Label>

        <Value>
          {formatCents(
            plus(totalAmountPaid, depositInvoice?.tipCents || 0),
            order?.bakery?.currency,
          )}
        </Value>
      </ItemRow>

      <ItemRow>
        <Label>Remaining balance</Label>

        <Value>{formatCents(remainingBalance, order?.bakery?.currency)}</Value>
      </ItemRow>
    </Container>
  )
}

const AmountContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
})
