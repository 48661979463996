import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { FetchError } from 'shared/components/FetchError'
import { PAGES } from './constants'
import { Spinner } from 'shared/components/Spinner'
import { useOrderContext } from 'web/contexts/orderContext'

const Navigation = (props) =>
  useRoutes([
    ...PAGES.map(({ name: path, component: Component }) => ({
      path,
      element: <Component {...props} />,
    })),
    {
      path: '/',
      element: <Navigate to="/" replace />,
    },
  ])

export const OrderRequestPage = () => {
  const { error, loading, order, refetch, setOrder } = useOrderContext()

  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  if (!order || loading) return <Spinner page />

  if (order.state !== 'customerBuilding')
    return <Navigate to={`/orders/${order.orderNumber}`} replace />

  return <Navigation order={order} setOrder={setOrder} bakerySlug={order?.bakery?.slug} />
}
