import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js'
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom'
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux'

import { AccountPage } from './pages/Account/Account'
import { apolloClient, setStore } from 'shared/services/graphqlClient'
import { AuthModal } from './components/AuthModal/AuthModal'
import { BakeryDetailsPage } from 'web/pages/Bakery/Details/Details'
import { BakeryPageLayout } from 'web/components/BakeryPageLayout'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { bootstrap } from 'shared/reducers/accountMutations'
import { ChatPage } from 'web/pages/Chat/Chat'
import { Configuration } from 'shared/services/Configuration'
import { ContractViewPage } from './pages/ContractViewPage'
import { FAQ } from 'web/pages/Bakery/Details/FAQ'
import { Gallery } from 'web/pages/Bakery/Details/Gallery'
import { getCanonicalUrl } from 'web/utils/getCanonicalUrl'
import { ImageLightbox } from 'web/components/ImageLightbox'
import { InvoiceProvider } from 'shared/contexts/InvoiceContext'
import { ItemDetails } from './pages/Bakery/Details/ItemDetails'
import { ItemRedirect } from './pages/Bakery/Details/ItemRedirect'
import { MobileNumberModal } from './components/MobileNumberModal/MobileNumberModal'
import { NewOrderRequestPage } from './pages/OrderRequest/NewOrderRequest'
import { NotificationSettingsPage } from './pages/NotificationSettings'
import { OAuthRedirect } from './pages/OAuthRedirect'
import { Offerings } from './pages/Bakery/Details/Offerings'
import { OrderDetailsPage } from './pages/OrderDetails'
import { OrderPageLayout } from 'web/components/OrderPageLayout'
import { OrderProvider } from './contexts/orderContext'
import { OrderRequestPage } from './pages/OrderRequest/OrderRequest'
import { PasswordReset } from './pages/PasswordReset'
import { ReviewForm, Reviews } from 'web/pages/Bakery/Details/Reviews'
import { SignInRequiredPage } from 'web/pages/SignInRequired'
import { Spinner } from 'shared/components/Spinner'
import { store } from 'shared/reducers/store'
import { ThemeProvider } from './contexts/ThemeContext'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { WindowDimensionsProvider } from './components/WindowDimensionsProvider'

setStore(store)
const stripePromise = loadStripe(Configuration.stripeToken)

const RedirectToWebsite = () => {
  window.location.replace('https://www.bakesy.app')
  return <div />
}

const Navigation = ({ user }) =>
  useRoutes([
    {
      path: '/oauth-redirect',
      element: (
        <OrderPageLayout>
          <OAuthRedirect />
        </OrderPageLayout>
      ),
    },
    {
      path: '/login',
      element: user ? (
        <Navigate to="/chat" replace />
      ) : (
        <OrderPageLayout>
          <SignInRequiredPage />
        </OrderPageLayout>
      ),
    },
    {
      path: '/new-password',
      element: user ? (
        <Navigate to="/" replace />
      ) : (
        <OrderPageLayout>
          <PasswordReset />
        </OrderPageLayout>
      ),
    },
    {
      path: '/chat',
      element: <OrderPageLayout>{user ? <ChatPage /> : <RedirectToWebsite />}</OrderPageLayout>,
    },
    {
      path: '/account',
      element: <OrderPageLayout>{user ? <AccountPage /> : <RedirectToWebsite />}</OrderPageLayout>,
    },
    {
      path: '/notification-settings',
      element: (
        <OrderPageLayout>
          {user ? <NotificationSettingsPage /> : <RedirectToWebsite />}
        </OrderPageLayout>
      ),
    },
    {
      path: '/o/:orderNumber',
      element: <Navigate to={`/orders/${window.location.pathname.split('/')[2]}`} replace />,
    },
    {
      path: '/orders/:orderNumber',
      element: (
        <OrderPageLayout>
          <OrderDetailsPage />
        </OrderPageLayout>
      ),
    },
    {
      path: '/print-order/:orderNumber',
      element: (
        <OrderPageLayout>
          <OrderDetailsPage printOrder />
        </OrderPageLayout>
      ),
    },
    {
      path: '/b/:slug',
      element: (
        <BakeryPageLayout>
          <BakeryDetailsPage />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/b/:slug/faqs',
      element: (
        <BakeryPageLayout>
          <FAQ />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/b/:slug/gallery',
      element: (
        <BakeryPageLayout>
          <Gallery />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/b/:slug/presale',
      element: (
        <BakeryPageLayout>
          <Navigate to={`/b/${window.location.pathname.split('/')[2]}/category/presale`} replace />,
        </BakeryPageLayout>
      ),
    },
    {
      path: '/b/:slug/products/:productSlug',
      element: (
        <BakeryPageLayout>
          <ItemDetails />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/item-details/:slug/:id',
      element: <ItemRedirect />,
    },
    {
      path: '/order-request/:orderId/*',
      element: (
        <OrderPageLayout>
          <OrderProvider>
            <OrderRequestPage />
          </OrderProvider>
        </OrderPageLayout>
      ),
    },
    {
      path: '/order-request/new/:bakeryId',
      element: <NewOrderRequestPage />,
    },
    {
      path: '/b/:slug/category/:categorySlug',
      element: (
        <BakeryPageLayout>
          <Offerings />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/contracts/:orderNumber',
      element: (
        <OrderPageLayout>
          <InvoiceProvider>
            <ContractViewPage />
          </InvoiceProvider>
        </OrderPageLayout>
      ),
    },
    {
      path: '/b/:slug/reviews',
      element: (
        <BakeryPageLayout>
          <Reviews />
        </BakeryPageLayout>
      ),
    },
    {
      path: '/review/:orderNumber',
      element: <ReviewForm />,
    },
    {
      path: '*',
      element: <RedirectToWebsite />,
    },
  ])

const Inner = () => {
  const bootstrapped = useSelector((state) => state.account.bootstrapped)
  const user = useCurrentUser()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(bootstrap())
  }, [dispatch])

  return bootstrapped ? (
    <>
      <Router>
        <Navigation user={user} />
      </Router>

      {!user && <AuthModal />}
      {user && !user.mobileNumber && <MobileNumberModal />}
      <ImageLightbox />
    </>
  ) : (
    <Spinner fullScreen />
  )
}

const TITLE = 'Bakesy: The All-in-One Home Bakery App'
const DESCRIPTION =
  'The simple way to manage your home bakery. From start to finish, complete the entire ordering process with Bakesy.'

export const App = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{TITLE}</title>
      <meta property="og:title" content={TITLE} />
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={`${Configuration.apiUrl}/logo-white-bg-og-v1.png`} />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
      <link rel="canonical" href={getCanonicalUrl()} />
    </Helmet>
    <ApolloProvider client={apolloClient}>
      <ReduxProvider store={store}>
        <Elements stripe={stripePromise}>
          <WindowDimensionsProvider>
            <ThemeProvider>
              <BannerProvider>
                <Inner />
              </BannerProvider>
            </ThemeProvider>
          </WindowDimensionsProvider>
        </Elements>
      </ReduxProvider>
    </ApolloProvider>
  </>
)
