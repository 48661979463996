import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { BAKERY_FAQ } from './Queries'
import { CardBackground } from 'web/styles/shared'
import { Collapsible } from 'web/components/Collapsible'
import { FetchError } from 'shared/components/FetchError'
import { FixedWidthContainer } from 'web/styles/responsive'
import { MainSubtitle } from './Details'
import { Spinner } from 'shared/components/Spinner'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const FAQ = () => {
  const { isMdUp } = useResponsiveLayout()
  const { slug } = useParams()
  const { data, loading, error, refetch } = useQuery(BAKERY_FAQ, { variables: { slug } })
  const { layoutHeight } = useWindowDimensions()

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  const {
    bakery: {
      faqs,
      premiumTheme: { primaryFont },
    },
  } = data || {}

  return (
    <Container minHeight={layoutHeight}>
      <MainColumn isMdUp={isMdUp}>
        <Card>
          <Heading font={primaryFont}>Frequently Asked Questions</Heading>
          {faqs.map((faq) => (
            <Collapsible Header={faq.title} key={faq.title}>
              {faq.description}
            </Collapsible>
          ))}
        </Card>
      </MainColumn>
    </Container>
  )
}
const Container = styled(FixedWidthContainer)(
  ({ minHeight }) => `
margin-top: 40px;
margin-bottom: 40px;
min-height: ${minHeight - 75}px;
`,
)

const Card = styled.div({
  ...CardBackground,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
  marginBottom: 20,
})

const Heading = styled(MainSubtitle)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  align-self: center;
  text-align: center;
`

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
`
