import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categories: [],
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push(action.payload)
    },
    removeCategory: (state, action) => {
      const defaultIndex = state.categories.findIndex((category) => category.default)
      const index = state.categories.findIndex((category) => category.id === action.payload)

      const defaultCategory = state.categories[defaultIndex]
      const category = state.categories[index]

      defaultCategory.offerings = [...category.offerings, ...defaultCategory.offerings]
      defaultCategory.offeringsCount = defaultCategory.offerings.length
      if (index > -1) state.categories.splice(index, 1)
    },
    replaceCategory: (state, action) => {
      const index = state.categories.findIndex((category) => category.id === action.payload.id)
      if (index > -1) state.categories[index] = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
  },
})

export const { addCategory, removeCategory, replaceCategory, setCategories } =
  categoriesSlice.actions

export const categoriesReducer = categoriesSlice.reducer
