import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { faTag } from '@fortawesome/pro-light-svg-icons'

import { BooleanInput, MobileNumberField, TextAreaField, TextField } from 'web/components/form'
import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'
import { Header } from 'web/pages/OrderRequest/styles'
import { Icon } from 'shared/components/Icon/Icon'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { Text } from 'shared/components/Cross'
import { toRawMobileNumber } from 'shared/utils/phone'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const OtherDetails = ({ order, setOrder }) => {
  const isInstantCheckout =
    !!order.bakery.instantCheckoutMethod &&
    order.orderItems.every((item) => item.offering.enableInstantCheckout)

  const [errors, setErrors] = useState({})
  const [agree, setAgree] = useState(!!order.bakery.termsAndConditions || isInstantCheckout)
  const [name, setName] = useState(order.customer?.name || '')
  const [discountCode, setDiscountCode] = useState('')
  const [email, setEmail] = useState(order.customer?.email || '')
  const [mobileNumber, setMobileNumber] = useState(order.customer?.mobileNumber || '')
  const [phoneCode, setPhoneCode] = useState(
    order?.bakery?.user?.countryCode
      ? { countryCode: order?.bakery?.user?.countryCode }
      : { countryCode: 'US' },
  )
  const [rawMobileNumber, setRawMobileNumber] = useState('')
  const [otherDetails, setOtherDetails] = useState(order.otherDetails || '')

  const { loading, saveOrder, updateOrder } = useUpdateOrder({ order, setOrder })
  const activeDiscount = useMemo(() => {
    return order.orderAdjustments.filter((oa) => !!oa.adjustment.code)[0] || {}
  }, [order])

  const applyCode = useCallback(async () => {
    if (!discountCode) return

    const data = await updateOrder({ input: { discountCode } })

    if (data.updatedOrder?.orderAdjustments?.findIndex((oa) => !!oa.adjustment.code) < 0)
      setErrors((ps) => ({ ...ps, order: { discountCode: 'invalid' } }))
  }, [discountCode, updateOrder])

  const deactivateDiscount = useCallback(async () => {
    if (!activeDiscount) return

    const data = await updateOrder({
      input: { discountCode: activeDiscount.adjustment?.code, deactivateDiscount: true },
    })

    if (!data.updatedOrder?.orderAdjustments?.findIndex((oa) => !!oa.adjustment.code) < 0)
      setErrors((ps) => ({
        ...ps,
        order: { discountCode: 'Something went wrong while deactivating discount' },
      }))
  }, [activeDiscount, updateOrder])

  const onNext = useCallback(async () => {
    if (!agree) return

    const input = {
      otherDetails,
      transition: order.instantCheckoutAllowed ? 'instantCheckout' : 'completeCustomerInitialForm',
      customerContact: {
        name,
        email,
        mobileNumber: phoneCode?.code + rawMobileNumber,
        countryCode: phoneCode?.countryCode,
      },
    }
    setErrors(await saveOrder({ input }))
  }, [
    saveOrder,
    otherDetails,
    name,
    email,
    rawMobileNumber,
    agree,
    phoneCode,
    order.instantCheckoutAllowed,
  ])

  const canSubmit = agree && name && email && !loading

  const handleDiscountCodeChange = (evt) => {
    setDiscountCode(evt.target.value)
    if (errors?.order?.discountCode)
      setErrors((ps) => ({ ...ps, order: { ...ps.order, discountCode: null } }))
  }

  return (
    <OrderRequestLayout nextDisabled={!canSubmit} onNext={onNext} order={order}>
      <Header>Contact information</Header>
      <TextField
        onChange={(evt) => setName(evt.target.value)}
        value={name}
        placeholder="Full Name"
        name="name"
        style={{ marginBottom: 20 }}
      />
      <TextField
        Container={EmailContainer}
        onChange={(evt) => setEmail(evt.target.value)}
        value={email}
        placeholder="Email"
        error={errors?.order?.customerContact?.email}
        name="email"
        type="email"
        style={{ marginBottom: 20 }}
      />
      <MobileNumberField
        Container={MobileNumberContainer}
        onChange={(evt) => {
          setMobileNumber(evt.target.value)
          setRawMobileNumber(toRawMobileNumber(evt.target.value))
        }}
        value={mobileNumber}
        error={errors?.order?.customerContact?.mobileNumber}
        phoneCode={phoneCode}
        setPhoneCode={setPhoneCode}
      />
      {order?.bakery?.hasDiscountCodes ? (
        activeDiscount.adjustment?.code ? (
          <>
            <CouponCodeContainer>
              <CouponCodeOuterWrapper>
                <CouponCodeWrapper>
                  <Icon icon={faTag} size={20} flip="horizontal" color={Colors.brand} />
                  <CouponCodeLabel>Coupon Code Used:</CouponCodeLabel>
                </CouponCodeWrapper>
                <CouponCodeText>{activeDiscount.adjustment?.code}</CouponCodeText>
                <CouponDeactivateButton type="button" onClick={deactivateDiscount}>
                  Deactivate
                </CouponDeactivateButton>
              </CouponCodeOuterWrapper>

              {errors?.order?.discountCode && <ErrorMessage error={errors?.order?.discountCode} />}
            </CouponCodeContainer>
          </>
        ) : (
          <CouponInputContainer>
            <CouponInput
              onChange={handleDiscountCodeChange}
              value={discountCode}
              placeholder="Coupon Code (optional)"
              name="couponCode"
              style={{ flex: 1 }}
              error={errors?.order?.discountCode}
            />
            <CouponInputButton type="button" onClick={applyCode}>
              Apply
            </CouponInputButton>
          </CouponInputContainer>
        )
      ) : null}
      <StyledTextArea
        onChange={(evt) => setOtherDetails(evt.target.value)}
        value={otherDetails}
        placeholder={'Additional order notes (optional)\n\n(pickup details, allergens, etc.)'}
      />
      {!order.bakery.termsAndConditions && !isInstantCheckout && (
        <AgreementContainer>
          <BooleanInput
            name="agree"
            label="I understand that placing an 'order request' does not confirm my order."
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
            labelStyle={{ fontStyle: 'italic' }}
          />
        </AgreementContainer>
      )}
    </OrderRequestLayout>
  )
}

const EmailContainer = styled.div({ flexGrow: 1 })

const MobileNumberContainer = styled.div({ flexGrow: 1, marginBottom: 20 })

export const StyledTextArea = styled(TextAreaField)({
  height: 100,
  '&::placeholder': {
    fontStyle: 'italic',
    color: Colors.grey50,
  },
})

export const AgreementContainer = styled.div({
  marginTop: 20,
  marginBottom: 20,
  padding: 0,
})

const CouponInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  position: 'relative',
})

const CouponInput = styled(TextField)({})

const couponButtonsCommon = {
  padding: '7px 15px',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
}

const CouponInputButton = styled(Button)({
  ...couponButtonsCommon,
  color: Colors.brand,
  position: 'absolute',
  right: 0,
  top: 2,
})

const CouponDeactivateButton = styled(Button)({
  ...couponButtonsCommon,
  color: Colors.orange,
  marginLeft: 20,
})

const CouponCodeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

const CouponCodeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
})

const CouponCodeLabel = styled(Text)({
  fontSize: 20,
  fontWeight: 500,
})

const CouponCodeText = styled(CouponCodeLabel)({
  color: Colors.green,
})

const CouponCodeOuterWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  alignItems: 'center',
})
