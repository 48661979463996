import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { BAKERY_ITEM_DETAILS } from './Queries'
import { Button } from 'web/components/Button'
import { Colors, stockTextColor } from 'shared/styles/Colors'
import { covertStockToDozens } from 'shared/utils/number'
import { FetchError } from 'shared/components/FetchError'
import { FixedWidthContainer } from 'web/styles/responsive'
import { fontSizeAdjustments } from 'shared/styles/PremiumThemes'
import { formatCents } from 'shared/utils/currency'
import { MultiLineText } from 'web/components/form'
import { Spinner } from 'shared/components/Spinner'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const ItemDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { productSlug, slug } = useParams()
  const { isMdUp } = useResponsiveLayout()
  const { primaryColor, actionButtonName, primaryFont } = useThemeContext()
  const { layoutHeight } = useWindowDimensions()

  const { data, loading, error, refetch } = useQuery(BAKERY_ITEM_DETAILS, {
    variables: { slug, offeringSlug: productSlug },
  })

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  const bakery = data.bakery
  const item = data.offering

  if (!item) return <Navigate to={`/b/${slug}`} />

  const renderArrowPrev = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <Arrow
        type="button"
        aria-label={label}
        className="control-arrow control-prev"
        onClick={onClickHandler}
      />
    )

  const renderArrowNext = (onClickHandler, hasNext, label) =>
    hasNext && (
      <Arrow
        type="button"
        aria-label={label}
        className="control-arrow control-next"
        onClick={onClickHandler}
      />
    )

  const isMulti = item.images.length > 1
  const { dozenOnly } = item
  const stock = covertStockToDozens(item.stock, dozenOnly)
  const hasStock = stock !== null

  return (
    <Container $minHeight={layoutHeight}>
      <MainColumn>
        <Row>
          <Back onClick={() => navigate(-1)}>
            <ArrowIcon icon={faArrowLeft} $isMdUp={isMdUp} />
            <Text>Back {location?.state?.fromMain ? '' : ' to products'}</Text>
          </Back>
        </Row>

        <ItemContainer $isMdUp={isMdUp}>
          <CarouselContainer $isMdUp={isMdUp}>
            <Carousel
              dynamicHeight
              showThumbs={false}
              showStatus={isMulti}
              showIndicators={isMulti}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={100}
              renderArrowPrev={renderArrowPrev}
              renderArrowNext={renderArrowNext}
            >
              {item.images.map((image) => (
                <ItemImage img src={image.fullUrl} key={image.id + image.key} alt={item.name} />
              ))}
            </Carousel>
          </CarouselContainer>

          <ItemContent $isMdUp={isMdUp}>
            <ItemName fontFamily={primaryFont}>{item.name}</ItemName>
            {hasStock && stock <= 10 && (
              <ItemStock color={stockTextColor(stock)}>
                {stock > 0 ? `(${stock}${dozenOnly ? ' dozen' : ''} left)` : 'Out of stock'}
              </ItemStock>
            )}

            {item.priceType !== 'noPrice' && (
              <ItemPrice color={primaryColor}>
                {bakery.currency &&
                  `${item.priceType === 'variable' ? 'Starting at' : ''} ${formatCents(
                    item.priceCents,
                    bakery.currency,
                  )}`}
              </ItemPrice>
            )}

            {item.description && (
              <MultiLineText Container={ItemDescription}>{item.description}</MultiLineText>
            )}
            {!bakery.ordersPaused && (stock > 0 || !hasStock) && (
              <RequestButtonContainer>
                <Button
                  to={`/order-request/new/${bakery.id}`}
                  state={{ itemId: item.id }}
                  color={primaryColor}
                  size="large"
                  fullWidth
                >
                  {actionButtonName}
                </Button>
              </RequestButtonContainer>
            )}
          </ItemContent>
        </ItemContainer>
      </MainColumn>
    </Container>
  )
}

const Container = styled(FixedWidthContainer)(
  ({ $minHeight }) => `
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: ${Colors.white};
    minHeight: ${$minHeight}
`,
)

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5;
  font-weight: 600;
`

const Back = styled.span`
  cursor: pointer;
`

const ArrowIcon = styled(FontAwesomeIcon)(
  ({ $isMdUp }) => `
    margin-left: ${$isMdUp ? '40px' : '10px'};
    margin-right: 10px;
    padding-bottom: 2px;
    transform: translateY(15%)
  `,
)

const Text = styled.span`
  font-size: 16px;
`

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const Arrow = styled.button`
  opacity: 1 !important;
  transform: scale(1.5);
`

const ItemContainer = styled.div(
  ({ $isMdUp }) => `
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
    align-items: center;
    padding-bottom: 60px;
    padding-top: 60px;
    padding-left: ${$isMdUp ? '20px' : '0'};
    margin-right: ${$isMdUp ? '20px' : '0'};
    flex-direction: ${$isMdUp ? 'row' : 'column'};
    padding-top: ${$isMdUp ? '60px' : '20px'};
  `,
)

const CarouselContainer = styled.div(
  ({ $isMdUp }) => `
    width: ${$isMdUp ? '45%' : '100%'};
  `,
)

const ItemImage = styled.img`
  object-fit: cover;
`

const ItemContent = styled.div(
  ({ $isMdUp }) => `
    padding: ${$isMdUp ? '20px 0 0 50px' : '20px 0 0'};
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: start;
  `,
)

const ItemName = styled.h1(
  ({ fontFamily }) => `
    margin: 0;
    font-size: ${2.5 + (fontSizeAdjustments[fontFamily] || 0)}rem;
    font-weight: 500;
    `,
)

const ItemPrice = styled.div(
  ({ color }) => `
    color: ${color};
    font-size: 21px;
    font-weight: 600;
    width: 105px;
  `,
)

const ItemStock = styled(ItemPrice)({
  width: 'auto',
  marginBottom: 10,
  marginTop: 10,
  fontSize: 18,
})

const ItemDescription = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: ${Colors.grey80};
  margin-right: 50px;
`

const RequestButtonContainer = styled.div`
  margin: 40px auto 0;
  width: 300px;
`
