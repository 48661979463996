import React, { createContext, useContext, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { selectBakery } from 'shared/reducers/account'
import { useThemeContext } from './ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const BannerContext = createContext()

export const BannerProvider = ({ children }) => {
  const bakery = useSelector(selectBakery)
  const { setDimensions } = useWindowDimensions()
  const { secondaryFont } = useThemeContext()

  const [hidden, setHidden] = useState(false)
  const handleHide = () => setHidden(true)

  const compRef = useRef()

  useLayoutEffect(() => {
    if (compRef.current) {
      setDimensions((ps) => ({
        ...ps,
        bannerHeight: compRef.current.clientHeight,
      }))
    }
  }, [setDimensions, bakery?.announcementBanner?.message])

  if (window.location.pathname.includes('order-request')) return children

  return (
    <BannerContext.Provider value={null}>
      {bakery && bakery.enableAnnouncementBanner && !hidden && (
        <BannerContainer
          backgroundColor={bakery?.announcementBanner?.backgroundColor}
          fontFamily={secondaryFont}
          ref={compRef}
        >
          <BannerText $color={bakery?.announcementBanner?.fontColor}>
            {bakery?.announcementBanner?.message}
          </BannerText>
          <CloseButton onClick={handleHide}>&times;</CloseButton>
        </BannerContainer>
      )}
      {children}
    </BannerContext.Provider>
  )
}

export const useBannerContext = () => useContext(BannerContext)

const BannerContainer = styled.div(({ backgroundColor, fontFamily }) => ({
  backgroundColor,
  padding: '10px 0 10px 10px !important',
  display: 'flex',
  flexDirection: 'row',
  fontFamily,
}))

const BannerText = styled.div(({ $color }) => ({
  color: $color,
  width: '100%',
  whiteSpace: 'pre-wrap',
}))

const CloseButton = styled.div({
  color: 'black !important',
  padding: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 24,
  width: 30,
  height: 30,
  cursor: 'pointer',
})
