import React from 'react'
import { faCartShopping } from '@fortawesome/pro-light-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { Divider, Helper, ItemDot, Row, Small, Strong } from 'shared/components/Invoice/styles'
import { formatCents } from 'shared/utils/currency'
import { Icon } from 'shared/components/Icon/Icon'
import { showUnit } from 'shared/utils/orderItem'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const ItemSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()

  if (!show) return null
  if (!order.orderItems.length) return null

  const renderPrice = (offering) => {
    const shouldRender =
      Configuration.isNative && offering.priceCents && offering?.priceType === 'fixed'

    if (!shouldRender) return ''

    return ` - ${formatCents(offering.priceCents, order?.bakery?.currency)}`
  }

  return (
    <Container>
      <Row $marginBottom={10}>
        <Icon icon={faCartShopping} size={20} color={Colors.blue} />

        <Strong $marginLeft={10}>Items:</Strong>
      </Row>

      {order.orderItems.map((orderItem) => (
        <React.Fragment key={orderItem.id}>
          <Row>
            <ItemDot />

            <Cross.Text>
              {orderItem.offering?.name}
              {renderPrice(orderItem.offering)}

              <Small>
                &nbsp;({orderItem.quantity} {showUnit(orderItem)})
              </Small>
            </Cross.Text>
          </Row>

          {!!orderItem.spec && (
            <Row>
              <ItemDot $spacer />
              <Helper>{orderItem.spec}</Helper>
            </Row>
          )}
        </React.Fragment>
      ))}

      <Divider />
    </Container>
  )
}
