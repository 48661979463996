/* eslint-disable prettier/prettier */
import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { Fonts } from 'shared/styles/Typography'
import { styler } from 'shared/utils/styler'

export const Row = styler(Cross.View)(
  ({ $marginBottom = 0, $marginTop = 0 }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: $marginBottom,
    marginTop: $marginTop,
  }),
)

export const Column = styler(Cross.View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: 5,
})

export const Strong = styler(Cross.Text)(
  ({ $marginLeft = 0, $marginRight = 0 }) => ({
    fontWeight: !Configuration.isNative && 'bold',
    fontFamily: Configuration.isNative && Fonts.SourceSansPro.SemiBold,
    marginLeft: $marginLeft,
    marginRight: $marginRight,
  }),
)

export const SectionContainer = styler(Cross.View)({
  backgroundColor: Colors.white,
  borderRadius: 4,
  marginBottom: 20,
  padding: 20,
  border: `1px solid ${Colors.grey37}`,
  boxShadow: `0 0 3px 0 ${Colors.grey37}`,
})

export const PaymentContainer = styler(Cross.View)({
  backgroundColor: Colors.white,
  borderRadius: 4,
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  marginBottom: 20,
  border: `1px solid ${Colors.black}`,
  padding: 0,
})

export const ContractContainer = styler(Cross.View)({
  backgroundColor: Colors.white,
  borderRadius: 4,
  boxShadow: `0 0 3px 0 ${Colors.grey37}`,
  border: `1px solid ${Colors.grey37}`,
  marginBottom: 20,
  padding: 0,
})

export const Title = styler(Cross.View)({
  fontSize: 32,
  fontWeight: 'bold',
  marginRight: 'auto',
})

export const SubTitle = styler(Cross.View)({
  fontSize: 22,
  color: Colors.red,
})

export const Divider = styler(Cross.View)(
  ({ $marginBottom = 10, $marginTop = 10 }) => ({
    backgroundColor: Colors.grey10,
    height: 1,
    marginBottom: $marginBottom,
    marginTop: $marginTop,
  }),
)

export const TextDivider = styler(Cross.View)`
  font-size: 20px;
  font-family: ${Fonts.SourceSansPro.Bold};
  color: ${Colors.grey50};
  display: flex;
  align-items: center;
  margin: 30px 0;

  &::before, &::after {
    content: '';
    height: 1px;
    background-color: ${Colors.grey50};
    flex-grow: 1;
  },

  &::before {
    margin-right: 1rem;
  }

  &:after {
    margin-left: 1rem;
  }
`

export const SpecText = styler(Cross.Text)({
  color: Colors.grey50,
  fontSize: Configuration.isNative ? 16 : '1.6rem',
})

export const ItemRow = styler(Row)({ justifyContent: 'space-between' })
export const Label = styler(Cross.Text)({ flex: 6 })
export const Spacer = styler(Cross.View)({ flex: 1 })
export const Container = styler(Cross.View)({ flex: 1 })
export const Value = styler(Cross.Text)({ flex: 1 })
export const Small = styler(Cross.Text)({ fontSize: 14 })
export const Active = styler(Cross.Text)({ color: Colors.blue })
export const Actions = styler(Cross.View)({ flexDirection: 'row' })

export const Helper = styler(Label)({
  color: Colors.grey50,
  fontSize: 14,
})

export const Info = styler(Value)(
  ({ $color = Colors.grey50, $bold }) => ({
    color: $color,
    fontSize: 14,
  }),
)

export const ItemDot = styler(Cross.View)(
  ({ $spacer = false }) => ({
    backgroundColor: $spacer ? 'transparent' : Colors.grey100,
    height: 4,
    width: 4,
    borderRadius: 5,
    marginRight: 10,
  }),
)

export const ImageLink = styler(Cross.LinkedView)({
  height: 75,
  width: 75,
  marginRight: 10,
  marginBottom: 10,
})

export const Image = styler(Cross.Image)({
  height: 75,
  width: 75,
})

export const PaymentProviders = styler(Cross.View)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
})

export const MenuContainer = styler(Cross.LinkedView)({
  flexDirection: 'row',
  backgroundColor: Colors.grey75,
  alignItems: 'center',
  justifyContent: 'center',
  height: 40,
  width: 40,
  borderRadius: 40,
})

export const Due = styler(Cross.Text)(
  ({ $closeweek = false }) => ({
    color: $closeweek ? Colors.red : Colors.grey50,
  }),
)
